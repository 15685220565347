/*

  Usage:

  const Page = () => {
    const handler = useCallback(() => {
      ....
    }, [])

    const containerRef = useRef(null) // It's optional (default is window)

    const removeScrollListener = useEventListener('scroll', handler, containerRef)

    return (
      <Fragment>
        <div ref={containerRef}>
          <Text />
          <Text />
        <div>
        <button ref={buttonRef}>Click me</button>
      </Fragment>
    )
  }

  The hook is used to install the handler on the event,
  inside the hook we automatically install a subscription to the did mount and an unsubscribe from the event

  The hook also returns a method that allows you to unsubscribe from a event

 */

import { useEffect, useRef, useCallback, RefObject } from 'react'


const useEventListener = (
  eventName: string,
  handler: EventListener,
  elementRef?: RefObject<HTMLElement>,
  passive?: boolean
) => {
  const handlerRef = useRef<EventListener>(null)

  useEffect(() => {
    handlerRef.current = handler
  }, [ handler ])

  const eventListener = useCallback<EventListener>((event) => {
    if (typeof handlerRef.current === 'function') {
      handlerRef.current(event)
    }
  }, [])

  const removeListener = useCallback(() => {
    const element = elementRef ? elementRef.current : window

    element.removeEventListener(eventName, eventListener)
  }, [ eventName, elementRef, eventListener ])

  useEffect(() => {
    const element = elementRef ? elementRef.current : window

    if (element && element.addEventListener) {
      element.addEventListener(eventName, eventListener, { passive })

      return () => {
        element.removeEventListener(eventName, eventListener)
      }
    }
  }, [ eventName, elementRef, eventListener, passive ])

  return removeListener
}


export default useEventListener
