import { useMap } from 'react-leaflet'
import { Form, useFormState } from 'formular'
import pointInPolygon from 'helpers/pointInPolygon'
import { useCallback, useEffect, useState } from 'react'
import L, { LeafletMouseEvent, PathEditor } from 'leaflet'

import marker from '../images/marker.svg'
import selectedMarker from '../images/selectedPlacemark.svg'

import transformLatLngsToCoords from './transformLatLngsToCoords'


const markerIcon = L.icon({
  iconUrl: marker,
  iconSize: [ 24, 37 ], // size of the icon
  iconAnchor: [ 12, 36 ], // point of the icon which will correspond to marker's location
})

const selectedMarkerIcon = L.icon({
  iconUrl: selectedMarker,
  iconSize: [ 24, 37 ], // size of the icon
  iconAnchor: [ 12, 36 ], // point of the icon which will correspond to marker's location
})

const useEditableMapContent = (form: Form<EditableMap.MapForm>) => {
  const map = useMap()

  const [ selectedElement, setSelectedElement ] = useState<(L.Marker | L.Polyline | L.Polygon) & { editor: PathEditor }>(null)

  useEffect(() => {
    const handleEditableStateChange = (event) => {
      setSelectedElement(event.type === 'editable:enable' ? event.layer : null)
    }

    map.on('editable:enable', handleEditableStateChange)
    map.on('editable:disable', handleEditableStateChange)

    return () => {
      map.off('editable:enable', handleEditableStateChange)
      map.off('editable:disable', handleEditableStateChange)
    }
  }, [ map ])

  const { values } = useFormState(form)
  const { placemarks, polylines, multiPolygons } = values
  const { placemarks: placemarksField, polylines: polylinesField, multiPolygons: multiPolygonsField } = form.fields

  const handleGeometryUpdate = useCallback((field, state, id, newGeometry: L.LatLngLiteral) => {
    const newGeometryTuple = transformLatLngsToCoords(newGeometry)

    field.set([
      ...state.filter(({ id: _id }) => _id !== id),
      {
        ...(state.find(({ id: _id }) => _id === id) || { id }),
        geometry: newGeometryTuple,
      },
    ])
  }, [])

  const handleAddPolygon = useCallback(() => {
    if (selectedElement) { // add shape to selected element
      selectedElement.editor.newShape()
    }
    else {
      const newPolygon = map.editTools.startPolygon()
      newPolygon.once('editable:drawing:commit', (event) => {
        handleGeometryUpdate(multiPolygonsField, multiPolygons, Math.random(), event.target.getLatLngs())
        newPolygon.remove()
      })
    }
  }, [ selectedElement, map.editTools, handleGeometryUpdate, multiPolygonsField, multiPolygons ])

  const handleToggleEdit = useCallback((event: LeafletMouseEvent | L.LeafletEvent) => {
    const newElement = event.target
    const oldElement = selectedElement
    const isSameElement = newElement === oldElement

    map.eachLayer((layer) => {
      if (layer instanceof L.Polygon || layer instanceof L.Polyline || layer instanceof L.Marker) {
        layer.disableEdit()
      }
    })

    if (!isSameElement) {
      newElement.enableEdit()
    }
  }, [ map, selectedElement ])

  const handleGeometryClick = useCallback((event: LeafletMouseEvent) => {
    if (event.target.options.uneditable) return
    if (map.editTools.drawing()) return // prevent handle when drawing

    if (
      event.target instanceof L.Polygon
      && (event.originalEvent.ctrlKey || event.originalEvent.metaKey)
      && event.target.editEnabled()
    ) {
      event.target.editor.newHole(event.latlng)
      return
    }
    handleToggleEdit(event)
  }, [ handleToggleEdit, map.editTools ])

  const handleDeletePolygon = useCallback((event: LeafletMouseEvent) => {
    const multiPolygonId = event.target.options.id
    const multiPolygonIndex = multiPolygons.findIndex(({ id }) => id === multiPolygonId)
    const multiPolygon = multiPolygons[multiPolygonIndex]

    // as multiPolygon field's value we can have both array of polygons and array of multiPolygons.
    // because when we update geometry in multiPolygon field we use geometry from leaflet event
    // and leaflet passes here polygon when we have drawn 1 polygon and multipolygon when multiple
    const isPolygon = !Array.isArray(multiPolygon.geometry[0][0][0])

    const polygons = isPolygon ? [ multiPolygon.geometry ] : multiPolygon.geometry

    const newPolygons = polygons.filter((polygon) => !pointInPolygon([ event.latlng.lat, event.latlng.lng ], polygon[0]))

    const newMultiPolygons = [
      ...multiPolygons.slice(0, multiPolygonIndex),
      ...(newPolygons.length ? [ {
        ...multiPolygon,
        geometry: newPolygons,
      } ] : []),
      ...multiPolygons.slice(multiPolygonIndex + 1),
    ]

    multiPolygonsField.set(newMultiPolygons)
  }, [ multiPolygons, multiPolygonsField ])

  useEffect(() => {
    if (selectedElement) {
      selectedElement.editor.reset() // workaround of vertex edit state lose on component rerender
    }
  })

  useEffect(() => {
    if (placemarks?.some(({ centerOnUpdate }) => centerOnUpdate)) {
      const placemarksToCenter = placemarks.filter(({ centerOnUpdate }) => centerOnUpdate)
      if (placemarksToCenter.length > 1) {
        console.error('More than 1 placemark with "centerOnUpdate" prop. Centering canceled')
      } else {
        const { geometry: [ lat, lng ] } = placemarksToCenter[0]
        map.fitBounds([ [ lat, lng ] ], { maxZoom: 18 })
      }
    }
  }, [ map, placemarks ])

  const [ contextMenuEvent, setContextMenuEvent ] = useState<L.LeafletMouseEvent | null>(null)

  useEffect(() => {
    const handleMapClick = (event: L.LeafletMouseEvent) => {
      if (event.target !== contextMenuEvent.target) {
        setContextMenuEvent(null)
      }
    }

    if (contextMenuEvent) {
      map.on('click', handleMapClick)
    }
    else {
      map.off('click', handleMapClick)
    }

    return () => {
      map.off('click', handleMapClick)
    }
  }, [ contextMenuEvent, map ])

  return {
    contextMenuEvent,
    selectedElement,
    markerIcon,
    selectedMarkerIcon,
    setContextMenuEvent,
    handleDeletePolygon,
    handleGeometryClick,
    handleGeometryUpdate,
    handleToggleEdit,
    handleAddPolygon,
  }
}

export default useEditableMapContent