import config from 'config'


// '&w=150&h=0&crop=1&asUrl=0'
const getImage = (_image, withResize = true) => {
  if (/^https?:\/\//.test(_image)) {
    return _image
  }

  // TODO add app domain to config
  const devUrl = config.appDomain ? 'https://' + config.appDomain : 'https://test.rosatom.city'
  const host = __DEV__ ? devUrl : ''
  const image = /^\//.test(_image) ? _image : `/${_image}`

  return image
    ? `${host}${withResize ? '/ajax.php?resizePhoto=' : ''}${image}`
    : null
}


export default getImage
