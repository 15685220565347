import React, { useEffect } from 'react'

import { useChatData } from 'models/chat'

import OpenButton from 'compositions/admin/mapLayers/AdminHeader/DropdownAction/components/OpenButton/OpenButton'


type ChatButtonProps = {
  className?: string
  initialCount: number
  setInitialCount: (count: number) => void
}

const ChatButton: React.FC<ChatButtonProps> = (props) => {
  const { className, initialCount, setInitialCount } = props

  const {
    chatState: {
      state: { channelsMeta },
    },
  } = useChatData({ metaOnly: true })

  const total = channelsMeta?.total

  useEffect(() => {
    if (typeof total === 'number') {
      setInitialCount(total)
    }
  }, [ total, setInitialCount ])

  return (
    <OpenButton
      className={className}
      icon="communication/message_20"
      count={typeof total === 'number' ? total : initialCount}
    />
  )
}


export default React.memo(ChatButton)
