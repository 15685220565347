import React from 'react'
import cx from 'classnames'

import { Icon, IconName, Text } from 'components/dataDisplay'

import s from './OpenButton.scss'


type DropdownActionButtonProps = {
  id?: string
  className?: string
  icon: IconName
  count: number
}

const OpenButton: React.FC<DropdownActionButtonProps> = (props) => {
  const { className, id, icon, count } = props

  return (
    <div
      id={id}
      className={cx(s.iconWrapper, className, 'flex items-center pointer')}
      role="button"
      style={{ height: '40px' }}
    >
      <div className="relative">
        {
          Boolean(count) && (
            <div
              className="radius-8 flex items-center justify-center z-1"
              style={{
                position: 'absolute',
                top: '-6px',
                right: count > 99 ? '-15px' : '-7px',
                width: count > 99 ? '24px' : '16px',
                height: '16px',
                backgroundColor: '#e14761',
              }}
            >
              <Text
                size="s10"
                color="white"
                message={count > 99 ? '99+' : String(count)}
              />
            </div>
          )
        }
        <Icon
          className={s.icon}
          name={icon}
          color="titanic"
        />
      </div>
    </div>
  )
}


export default React.memo(OpenButton)
