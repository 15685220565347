import React, { useMemo } from 'react'
import cx from 'classnames'

import { Tooltip } from 'components/feedback'
import { ButtonBase } from 'components/inputs'
import { CheckboxIcon, Icon, Text } from 'components/dataDisplay'

import s from '../../NotificationSettingsModal.scss'


type HeadingProps = {
  className?: string
  withSms?: boolean
  withEmail?: boolean
  activeList: {
    sms: boolean
    chat: boolean
    email: boolean
  }
  onCheckboxClick: (id: string) => void
}

const Heading: React.FC<HeadingProps> = (props) => {
  const { className, withSms, withEmail, activeList, onCheckboxClick } = props

  const titles = useMemo(() => {
    const result: { id: string, title: string, tooltip?: string, isActive: boolean }[] = [
      { id: 'chat', title: 'На сайте', isActive: activeList.chat },
    ]

    if (withEmail) {
      result.push({ id: 'email', title: 'На email', isActive: activeList.email })
    }

    if (withSms) {
      result.push({
        id: 'sms',
        title: 'По СМС',
        tooltip: 'Чтобы получать уведомления по СМС, необходимо в личном кабинете добавить номер телефона',
        isActive: activeList.sms,
      })
    }

    return result
  }, [ withSms, withEmail, activeList ])

  return (
    <div className={cx(className, s.grid, 'grid w-full')}>
      <Text
        className="opacity-48 whitespace-nowrap"
        size="s13-r"
        color="titanic"
        message="Раздел сайта"
      />
      {
        titles.map(({ id, title, tooltip, isActive }, index) => (
          <div key={index} className="flex items-center">
            <ButtonBase
              onClick={() => onCheckboxClick(id)}
            >
              <CheckboxIcon
                active={isActive}
              />
              <Text
                className="ml-8px opacity-48 whitespace-nowrap"
                size="s13-r"
                color="titanic"
                message={title}
              />
            </ButtonBase>
            {
              Boolean(tooltip) && (
                <div className="ml-8px flex items-center opacity-72" >
                  <Tooltip message={tooltip} position="top-left">
                    <Icon name="main/info_16" color="titanic" />
                  </Tooltip>
                </div>
              )
            }
          </div>
        ))
      }
    </div>
  )
}


export default React.memo(Heading)
