import React from 'react'
import 'leaflet-editable'
import 'leaflet.path.drag'
import 'leaflet/dist/leaflet.css'

import { NewMap } from 'compositions/map'

import EditableMapContent from '../EditableMapContent/EditableMapContent'

import useLeafletExtensions from '../NewMap/util/useLeafletExtensions'

import useEditableMap from './util/useEditableMap'


const EditableMap: React.FC<EditableMap.MapProps> = (props) => {
  const {
    className,
    form,
    center,
    withAddress,
    locateUser,
    fixedPlacemarks,
    withFullScreen = true,
  } = props

  const { isLoaded: isExtensionsLoaded } = useLeafletExtensions()

  const {
    zoomCenter, bounds, initialZoom, zoom, updateZoom, updateCenter,
  } = useEditableMap(form, center, withAddress)

  if (!isExtensionsLoaded) {
    return <></>
  }

  return (
    <NewMap
      className={className}
      center={zoomCenter}
      locateUser={locateUser}
      addressField={withAddress ? form.fields.address : null}
      whenCreated={(map) => bounds?.length && map.fitBounds(bounds, { maxZoom: initialZoom })}
      withFullScreen={withFullScreen}
      zoom={zoom}
      onZoomEnd={({ zoom, center }) => {
        updateZoom(zoom)
        updateCenter(center)
      }}
      drawingCursor="pointer"
      editable
    >
      <EditableMapContent
        fixedPlacemarks={fixedPlacemarks}
        form={form}
      />
    </NewMap>
  )
}


export default EditableMap
