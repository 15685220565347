import React, { useMemo } from 'react'
import config from 'config'
import links, { getLinkWithParams } from 'links'

import { getImage } from 'models/mapLayer'
import { useUser, useAccess, useRole } from 'models/user'

import { DropdownMenu } from 'components/navigation'
import { AvatarHorizontallyBlock } from 'components/dataDisplay'


type ProfileButtonProps = {
  className?: string
  tag: string
  withUserName?: boolean
}

const ProfileButton: React.FC<ProfileButtonProps> = (props) => {
  const { className, tag, withUserName = true } = props
  const { isAdmin } = useRole()
  const { user, handleLogout, isUserFetching } = useUser()
  const { id, firstName, lastName, pic, image, email } = user || {}
  const { roleRu, isFetching: isRoleFetching } = useAccess(tag)

  const items = useMemo(() => {
    if (config.isLegacyAuth) {
      return [
        {
          title: 'Личный кабинет',
          to: id ? getLinkWithParams(links.admin.users.editUser.root, { userId: id }) : '',
        },
        {
          title: 'Публичный раздел',
          to: links.home,
        },
        {
          title: 'Выход',
          to: links.logout,
        },
      ]
    }

    const result = [
      {
        title: 'Публичный раздел',
        to: links.home,
      },
      {
        title: 'Выход',
        onClick: handleLogout,
      },
    ]

    if (isAdmin) {
      return [
        {
          title: 'Личный кабинет',
          to: id ? getLinkWithParams(links.admin.users.editUser.root, { userId: id }) : '',
        },
        ...result,
      ]
    }

    return result
  }, [ id, isAdmin, handleLogout ])

  const userName = [ firstName, lastName ]
    .filter(Boolean)
    .join(' ')

  const position = roleRu || ''

  return (
    !isUserFetching && (
      <DropdownMenu
        className={className}
        items={items}
        placement="bottomRight"
        style="blank"
      >
        <AvatarHorizontallyBlock
          userName={withUserName ? userName || email : ''}
          position={withUserName ? position : ''}
          image={(
            config.isLegacyAuth
              ? (pic ? getImage(pic) : null)
              : (image ? getImage(image) : null)
          )}
          size={40}
          handleClick={() => {}}
        />
      </DropdownMenu>
    )
  )
}

export default ProfileButton