import { useCallback, useEffect, useMemo } from 'react'
import { useMutation, useQuery } from 'hooks'
import config from 'config'

import useNewUser from './useNewUser'
import modifyUser from './modifyUser'


const useUser = () => {
  const oldQueryOptions = useMemo(() => ({
    url: '/nginxApi.php?get=user',
    // cacheTime: 60 * 1000,
    modifyResult: (data) => {
      return {
        id: data?.userId,
      }
    },
  }), [])

  const queryOptions = useMemo(() => ({
    url: '/auth/rest/user',
    // cacheTime: 60 * 1000,
    modifyResult: (data: UserModel.UserApiData) => {
      const { status, user: userData } = data

      const hasAuth = status > 0
      const user = userData ? modifyUser(userData) : {}

      return {
        user,
        hasAuth,
      }
    },
  }), [])

  const {
    data,
    isFetching,
    errors,
  } = useQuery<{ hasAuth: boolean, user: UserModel.User }>(queryOptions)

  useEffect(() => {
    if (!isFetching && !data?.hasAuth) {
      // Remove userId cookie if not authorized
      document.cookie = 'userId=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
    }
  }, [ data, isFetching ])

  const { data: oldData, isFetching: isOldDataFetching } = useQuery<{ id: string }>(oldQueryOptions)

  const [ submit, { isSubmitting } ] = useMutation<UserModel.UserApiData>({
    url: '/auth/rest/',
  })

  const [ remove, { isSubmitting: isRemoveSubmitting } ] = useMutation<UserModel.UserApiData>({
    url: '/auth/rest/user',
  })

  const handleRemove = useCallback(async (userId) => {
    const { data, cacheStorage } = await remove({
      url: `/auth/rest/user/${userId}`,
      method: 'DELETE',
    })

    cacheStorage.reset(/\/auth\/rest\/user\/list/)
    cacheStorage.reset(new RegExp(`/auth/rest/user/${userId}`))
  }, [ remove ])

  const handleLogin = useCallback(async ({ login, password }: { login: string, password: string }) => {
    const { data, cacheStorage } = await submit({
      login,
      password,
    })

    window.dispatchEvent(new CustomEvent('login'))

    cacheStorage.set('/auth/rest/', {
      data,
      // expiration: 60 * 1000,
    })
    cacheStorage.reset('/auth/rest/user')
    console.log('reset', '/auth/rest/access')
    cacheStorage.reset('/auth/rest/access')
    cacheStorage.reset('/auth/rest/accesses')
    cacheStorage.reset('/nginxApi.php?get=user')
  }, [ submit ])

  const user = useMemo(() => {
    if (data?.user) {
      return {
        ...data.user,
        oldId: oldData?.id,
      }
    }

    return {} as UserModel.User
  }, [ data, oldData ])

  useEffect(() => {
    const logUser = (user) => {
      if (!user?.id) {
        window.loggedId = null
      }
      else if (window.loggedId !== user.id) {
        window.loggedId = user?.id
        console.log(`USER ID: ${user.id}`)
        console.log(`USER ID PHP: ${user?.oldId}`)
      }
    }

    window.logUser = window.logUser || logUser
    window.logUser(user)
  }, [ user ])

  return {
    hasAuth: data?.hasAuth,
    user,
    handleLogin,
    handleRemove,
    isUserFetching: isFetching || isOldDataFetching,
    isUserSubmitting: isSubmitting || isRemoveSubmitting,
  }
}

const hook = config.isLegacyAuth ? useUser : useNewUser


export default hook
