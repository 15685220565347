import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { useChannelsList, useSubscribe } from 'models/notifications'

import useAppealsSettings from './useAppealsSettings'


const smsModules = [ 'Оповещения', 'Уборка' ]

type State = Record<string, {
  chat?: boolean
  email?: boolean
  sms?: boolean
}>


const useNotificationSettings = () => {
  const [ selectedChannels, setState ] = useState<State>({})
  const { updateSubscriptions, isSubmitting } = useSubscribe()
  const { channelsList, isChannelsListFetching } = useChannelsList()
  const { appealsSettings, appealsChange, setAppealsSettings, updateAppealsSettings } = useAppealsSettings()

  const initialSelectedChannelsRef = useRef({})

  const baseChannels = useMemo(() => {
    return channelsList.filter(({ label }) => !smsModules.includes(label) && label !== 'Комментарии')
  }, [ channelsList ])

  const addressesChannels = useMemo(() => {
    return channelsList.filter(({ label }) => smsModules.includes(label))
  }, [ channelsList ])

  useEffect(() => {
    if (channelsList.length) {
      channelsList.forEach(({ value, subscribed, emailSubscribed, smsSubscribed }) => {
        const isActive = subscribed || emailSubscribed || smsSubscribed

        if (isActive) {
          initialSelectedChannelsRef.current[value] = {}

          if (subscribed) {
            initialSelectedChannelsRef.current[value].chat = true
          }
          if (emailSubscribed) {
            initialSelectedChannelsRef.current[value].email = true
          }
          if (smsSubscribed) {
            initialSelectedChannelsRef.current[value].sms = true
          }
        }
      })

      setState(initialSelectedChannelsRef.current)
    }
  }, [ channelsList ])

  const channelsToSubscribe = useMemo(() => {
    const result = {
      sms: [],
      chat: [],
      email: [],
    }

    Object.keys(selectedChannels).forEach((channelId) => {
      const selectedChannel = selectedChannels[channelId]

      Object.keys(selectedChannel).forEach((key) => {
        const isSubscribed = selectedChannel[key]
        const wasSubscribed = initialSelectedChannelsRef.current[channelId]?.[key]

        if (isSubscribed && !wasSubscribed) {
          result[key].push(channelId)
        }
      })
    })

    return result
  }, [ selectedChannels ])

  const channelsToUnsubscribe = useMemo(() => {
    const result = {
      sms: [],
      chat: [],
      email: [],
    }

    Object.keys(initialSelectedChannelsRef.current).forEach((channelId) => {
      const initialSelectedChannel = initialSelectedChannelsRef.current[channelId]

      Object.keys(initialSelectedChannel).forEach((key) => {
        const isSubscribed = selectedChannels[channelId]?.[key]

        if (!isSubscribed) {
          result[key].push(channelId)
        }
      })
    })

    return result
  }, [ selectedChannels ])

  const isAppealsChanged = useMemo(() => {
    const isEmailChanged = selectedChannels['1']?.email && appealsChange.email
    const isChatChanged = selectedChannels['1']?.chat && appealsChange.chat

    return isEmailChanged || isChatChanged
  }, [ appealsChange, selectedChannels ])

  const isChanged = useMemo(() => {
    const isSubscribed = Object.keys(channelsToSubscribe).some((key) => channelsToSubscribe[key].length)
    const isUnsubscribed = Object.keys(channelsToUnsubscribe).some((key) => channelsToUnsubscribe[key].length)

    return isSubscribed || isUnsubscribed || isAppealsChanged
  }, [ channelsToSubscribe, channelsToUnsubscribe, isAppealsChanged ])

  const activeList = useMemo(() => {
    const baseListValues = Object.keys(selectedChannels)
      .filter((key) => {
        const channelLabel = channelsList.find(({ value }) => String(value) === key)?.label

        return !smsModules.includes(channelLabel)
      })
      .map((key) => selectedChannels[key])

    const addressListValues = Object.keys(selectedChannels)
      .filter((key) => {
        const channelLabel = channelsList.find(({ value }) => String(value) === key)?.label

        return smsModules.includes(channelLabel)
      })
      .map((key) => selectedChannels[key])

    const isFullBaseList = baseListValues.length && baseListValues.length === baseChannels.length
    const isFullAddressList = addressListValues.length && addressListValues.length === addressesChannels.length

    return {
      base: {
        sms: isFullBaseList && baseListValues.every(({ sms }) => sms),
        chat: isFullBaseList && baseListValues.every(({ chat }) => chat),
        email: isFullBaseList && baseListValues.every(({ email }) => email),
      },
      addresses: {
        sms: isFullAddressList && addressListValues.every(({ sms }) => sms),
        chat: isFullAddressList && addressListValues.every(({ chat }) => chat),
        email: isFullAddressList && addressListValues.every(({ email }) => email),
      },
    }
  }, [ baseChannels, addressesChannels, selectedChannels, channelsList ])

  const selectAll = useCallback(({ type, channels, isActive }) => {
    setState((selectedChannels) => {
      const result = {}

      Object.values(channels).forEach(({ value }) => {
        const selectedChannel = selectedChannels[value]

        result[value] = {
          ...selectedChannel,
          [type]: !isActive,
        }
      })

      return {
        ...selectedChannels,
        ...result,
      }
    })
  }, [])

  const handleUpdate = useCallback(async () => {
    await Promise.all([
      updateSubscriptions({
        channelsToSubscribe,
        channelsToUnsubscribe,
      }),
      updateAppealsSettings(),
    ])
  }, [ channelsToSubscribe, channelsToUnsubscribe, updateSubscriptions, updateAppealsSettings ])

  const updateChannel = useCallback(({ channelId, type }) => {
    setState((selectedChannels) => ({
      ...selectedChannels,
      [channelId]: {
        ...selectedChannels[channelId],
        [type]: !selectedChannels[channelId]?.[type],
      },
    }))
  }, [])

  return {
    activeList,
    baseChannels,
    addressesChannels,
    selectedChannels,
    appealsSettings,
    isChanged,
    isSubmitting,
    isFetching: isChannelsListFetching,
    selectAll,
    handleUpdate,
    updateChannel,
    setAppealsSettings,
  }
}


export default useNotificationSettings
