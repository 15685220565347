/**
 * we need this hook when we have global variable L extended by some modules (i.e. 'leaflet-editable' and 'leaflet.path.drag')
 * and another microfrontend importing Leaflet, rewriting the global L and clearing these extensions
 *
 * this hook restores the global L to the original value it had before the second Leaflet inclusion
 */
import { useEffect, useState } from 'react'


const useLeafletExtensions = () => {
  const [ isLoaded, setIsLoaded ] = useState(false)

  useEffect(() => {
    // @ts-expect-error no typing for 'leaflet.path.drag' lib
    if (!window.L?.Handler?.PathDrag || !window.L?.MarkerClusterGroup) {
      // @ts-expect-error wrong typing leaflet
      window.L.noConflict()
    }
    setIsLoaded(true)
  }, [])

  return {
    isLoaded,
  }
}

export default useLeafletExtensions