import { useMemo } from 'react'
import dayjs from 'date'

import useImmortals from '../immortals/useImmortals'


const useImmortalsNotifications = ({ skip }: { skip: boolean }) => {
  const { heroes, isFetching, fetch } = useImmortals({ isAdmin: true, skip })

  const immortalsData = useMemo(() => {
    const notifications = heroes.map(({ fio, created, to }) => ({
      channelId: 'immortals',
      date: dayjs(created).format('DD MMMM в HH:mm'),
      title: 'Добавлен герой',
      text: fio,
      to,
    }))

    return {
      notifications,
      count: heroes.length,
    }
  }, [ heroes ])

  return {
    immortalsData,
    isImmortalsFetching: isFetching,
    fetchImmortals: fetch,
  }
}


export default useImmortalsNotifications
