import { useEffect } from 'react'
import { useObjectState } from 'hooks'

import { useUser } from 'models/user'

import useSocket from './useSocket'
import date from 'date'


export type State = {
  media: Record<number, ChatModel.Message[]> // todo update type
  messages: Record<number, ChatModel.Message[]>
  channels: ChatModel.Channel[]
  channelsMeta: ChatModel.ChannelsMeta
  channelsUsers: Record<number, ChatModel.ChannelUser[]>
  usersOnline: string[]
  lastActiveDates: Record<string, number>
}

type Input = {
  metaOnly?: boolean
}

const useChatData = (props?: Input) => {
  const { metaOnly } = props || {}

  const { user: { oldId } } = useUser()
  const { socket, isConnected } = useSocket()

  const [ state, setState ] = useObjectState<State>({
    media: {},
    messages: {},
    channels: [],
    channelsUsers: {},
    channelsMeta: {} as ChatModel.ChannelsMeta,
    usersOnline: [],
    lastActiveDates: {},
  })

  useEffect(() => {
    if (isConnected) {
      const setChannels = (channels) => {
        console.log('[CLIENT] getChannelsMeta', channels)
        setState({
          channels: channels?.filter(({ key }) => key >= 300) || [],
        })
      }

      const setChannelsMeta = (channelsMeta) => {
        console.log('[CLIENT] getChannelsMeta', channelsMeta)
        setState((state) => ({
          ...state,
          channelsMeta,
          channels: state.channels.map((channel) => {
            const channelMeta = channelsMeta[channel.key]
            const channelMetaBefore = state.channelsMeta[channel.key]

            const isChanged = channelMeta > channelMetaBefore

            if (isChanged) {
              return {
                ...channel,
                updated: date().format('YYYY-MM-DD HH:mm'),
              }
            }
            return channel
          }),
        }))

        // @ts-ignore
        if (typeof window.setChatCount === 'function' && channelsMeta) {
          // @ts-ignore
          window.setChatCount(channelsMeta.total || 0)
        }
      }

      const setUsersOnline = ({ usersOnline }) => {
        console.log('[CLIENT] getUsersOnline', usersOnline)

        setState((state) => ({
          usersOnline,
          lastActiveDates: Object.keys(state.lastActiveDates)
            .reduce((result, key) => {
              const formattedKey = key.replace(/^-/, '')

              if (!usersOnline.includes(formattedKey)) {
                result[key] = state.lastActiveDates[key]
              }

              return result
            }, {}),
        }))
      }

      const setLastActiveDate = ({ userId, lastActiveDate }) => {
        console.log('[CLIENT] getLastActiveDate', { userId, lastActiveDate })

        setState((state) => ({
          ...state,
          lastActiveDates: {
            ...state.lastActiveDates,
            [userId]: lastActiveDate,
          },
        }))
      }

      if (metaOnly) {
        // Non chat pages
        socket.on('getChannelsMeta', setChannelsMeta)
        socket.emit('getChannelsMeta')

        return () => {
          socket.off('getChannelsMeta')
        }
      }
      else {
        // Chat page
        socket.on('getChannelsList', setChannels)
        socket.on('getChannelsMeta', setChannelsMeta)
        socket.on('getUsersOnline', setUsersOnline)
        socket.on('getLastActiveDate', setLastActiveDate)

        socket.emit('getUsersOnline')
        socket.emit('getChannelsList')

        return () => {
          socket.off('getChannelsList')
          socket.off('getChannelsMeta')
          socket.off('getUsersOnline')
          socket.off('getLastActiveDate')
        }
      }
    }
  }, [ socket, isConnected, metaOnly, setState ])

  return {
    chatState: {
      state,
      setState,
    },
    socketState: {
      socket,
      isConnected,
    },
  }
}


export default useChatData
