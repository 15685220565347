import React from 'react'
import cx from 'classnames'
import { useField } from 'formular'
import { usePersonalOptions } from 'models/user'

import { ActionButton, ButtonBase } from 'components/inputs'
import { Icon, IconWithText } from 'components/dataDisplay'
import { openConfirmModal } from 'compositions/modals'

import { AddressSelectModal, openAddressSelectModal } from 'layers/profile/pages/HomePage/components/UserDataTab/components'

import s from './Addresses.scss'


type AddressesProps = {
  className?: string
}

const Addresses: React.FC<AddressesProps> = (props) => {
  const { className } = props
  const { personalOptions, isFetching, isSubmitting, addAddress, deleteAddress } = usePersonalOptions()

  const addressField = useField<string>()

  return (
    <div className={className}>
      {
        personalOptions?.addresses?.map(({ address, lat, lng }, index) => (
          <div
            key={index}
            className={cx('flex', {
              'pb-12px': personalOptions?.addresses[index + 1],
              'pt-12px': index,
              [s.border]: index,
            })}
          >
            <IconWithText
              className="flex-1"
              message={address}
              textProps={{
                size: 't16-20',
              }}
              color={lat && lng ? 'titanic' : 'fargo'}
              iconName="map/geopoint_16"
            />
            <ButtonBase
              className="ml-24px"
              onClick={() => openConfirmModal({
                title: `Удалить адрес ${address}?`,
                text: 'Это действие невозможно будет отменить',
                cancelButtonTitle: 'Не удалять',
                confirmButtonTitle: 'Да, удалить',
                onConfirm: () => {
                  deleteAddress(index)
                },
              })}
            >
              <Icon
                name="main/delete_16"
                color="fargo"
              />
            </ButtonBase>
          </div>
        ))
      }
      <ActionButton
        className={cx({
          'mt-24px': personalOptions?.addresses?.length,
        })}
        title="Добавить адрес"
        color="rocky"
        icon="main/add_16"
        disabled={isFetching || isSubmitting}
        onClick={() => openAddressSelectModal({
          title: 'Новый адрес',
          address: addressField,
          onClose: ({ address, lat, lng }) => {
            if (address && lat && lng) {
              addAddress({ address, lat, lng })
            }
          },
        })}
      />
      <AddressSelectModal />
    </div>
  )
}


export default Addresses
