import cx from 'classnames'
import React, { useCallback, useMemo, useState } from 'react'
import { LeafletEventHandlerFnMap, PointExpression } from 'leaflet'
import { Popup as LeafletPopup, PopupProps as LeafletPopupProps } from 'react-leaflet'

import { PopupContent } from './components'

import s from './Popup.scss'


type PopupCommonData = {
  title?: string
  subTitle?: string
  text?: string
  to?: string
}

export type BigImageData = PopupCommonData & {
  type: 'bigImage'
  image: string
  withGradient?: boolean
  isCarousel: boolean
  id?: string
  isFavorite?: boolean
  toggleFavorite?: () => void
}

type StaffListData = PopupCommonData & {
  type: 'staffList'
  staff?: MapLayer.Person[]
  image?: string
}

type BusCardData = PopupCommonData & {
  type: 'busCard'
  isFetching?: boolean
  withInvalid?: boolean
  isFavorite?: boolean
  transportType: PublicTransport.TransportTypeTitle
}

type SimpleCardData = PopupCommonData & {
  type: 'simpleCard'
  children?
}

// TODO handle types
export type PopupData = BigImageData | StaffListData | BusCardData | SimpleCardData

type PopupProps = LeafletPopupProps & {
  className?: string
  id?: string
  data?: PopupData | PopupData[]
  eventHandlers?: LeafletEventHandlerFnMap
  offset?: PointExpression
  isFavorite?: boolean
  unmountChildrenOnClose?: boolean
  toggleFavorite?: () => void
}

const Popup: React.FC<PopupProps> = (props) => {
  const { className, position, data, eventHandlers, offset = [ 0, 0 ], isFavorite, id, children,
    unmountChildrenOnClose, toggleFavorite, onClose, ...rest } = props

  const [ isOpen, setIsOpen ] = useState(false)

  const handleOpen = useCallback(() => {
    setIsOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    if (typeof onClose === 'function') {
      onClose()
    }
    setIsOpen(false)
  }, [ onClose ])

  const content = useMemo(() => (
    <PopupContent
      className={className}
      data={data}
      isFavorite={isFavorite}
      id={id}
      toggleFavorite={toggleFavorite}
    >
      {children}
    </PopupContent>
  ), [ children, className, data, id, isFavorite, toggleFavorite ])

  return (
    <LeafletPopup
      className={cx(s.popup, className)}
      position={position}
      onOpen={handleOpen}
      onClose={handleClose}
      eventHandlers={eventHandlers}
      offset={offset}
      {...rest}
    >
      {
        unmountChildrenOnClose ? (isOpen ? content : (<></>)) : content
      }
    </LeafletPopup>
  )
}


export default Popup
