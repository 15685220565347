import { useMemo } from 'react'
import config from 'config'
import { useQuery } from 'hooks'

import { useAuth } from 'containers/AuthProvider'


const useAccesses = (skip?: boolean) => {
  const { shortToken } = useAuth()

  const queryOptions = useMemo(() => ({
    url: config.isLegacyAuth ? '/auth/rest/access' : '/auth/rest/access' + `?token=${shortToken}`,
    skip: (config.isLegacyAuth ? false : !shortToken) || skip,
    clearOnFetch: false,
  }), [ shortToken, skip ])

  const {
    data,
    isFetching,
  } = useQuery<UserModel.AccessesApiData>(queryOptions)

  const accesses = useMemo(() => {
    if (data?.status >= 0 && data?.accesses) {
      return data.accesses
    }

    return []
  }, [ data ])

  return {
    accesses,
    isAccessesFetching: isFetching,
  }
}

export default useAccesses
