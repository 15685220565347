import { useCallback } from 'react'
import { useMutation } from 'hooks'


type Channels = {
  sms: string[]
  chat: string[]
  email: string[]
}

const useSubscribe = () => {
  const [ submit, { isSubmitting } ] = useMutation({
    url: '/ajax.php?action=saveEventsActiveOptions', // setForKey=Int&chat=1|0
  })

  const getPromises = useCallback(({ channels, subscribe }: { channels: Channels, subscribe: 1 | 0 }) => {
    const promises = []

    Object.keys(channels).forEach((key) => {
      channels[key].forEach((channel) => {
        promises.push(submit({
          url: `/ajax.php?action=saveEventsActiveOptions&setForKey=${channel}&${key}=${subscribe}`,
        }))
      })
    })

    return promises
  }, [ submit ])

  const updateSubscriptions = useCallback(async ({ channelsToSubscribe, channelsToUnsubscribe }: { channelsToSubscribe: Channels, channelsToUnsubscribe: Channels }) => {
    const subscribePromises = getPromises({ channels: channelsToSubscribe, subscribe: 1 })
    const unsubscribePromises = getPromises({ channels: channelsToUnsubscribe, subscribe: 0 })

    if (subscribePromises.length || unsubscribePromises.length) {
      const results = await Promise.all([ ...subscribePromises, ...unsubscribePromises ])

      const { data, cacheStorage } = results[results.length - 1]

      cacheStorage.reset(/ajax\.php\?action=chatChannelSearch/)
    }
  }, [ getPromises ])

  return {
    isSubmitting,
    updateSubscriptions,
  }
}


export default useSubscribe
