import { useEffect, useMemo } from 'react'
import { useHistory, useParams, usePathname } from 'router'
import links, { routeData } from 'links'

import { useUser, useAccess } from 'models/user'


const useVueRoutes = () => {
  const params = useParams()
  const history = useHistory()
  const pathname = usePathname()

  const data = routeData[pathname]

  const pathnameTemplate = useMemo(() => {
    let result = pathname

    if (params && pathname) {
      Object.keys(params).forEach((param) => {
        const value = params[param]

        result = result.replace(value, `:${param}`)
      })
    }

    return result
  }, [ params, pathname ])

  const { hasAuth, isUserFetching } = useUser()
  const { role, isFetching } = useAccess(data?.type === 'vue' ? data?.tag : null)

  useEffect(() => {
    const data = routeData[pathnameTemplate]
    const isAdminPage = /\/admin\//.test(pathnameTemplate) || /\/admin$/.test(pathnameTemplate)

    if (isAdminPage && !isUserFetching) {
      if (!hasAuth) {
        history.push(links.login)
      }
      else if (data?.type === 'vue' && data?.tag && ![ 'OPERATOR', 'MODERATOR', 'ADMIN' ].includes(role) && !isFetching) {
        history.replace(links.page403)
      }
    }
  }, [ pathnameTemplate, hasAuth, role, isFetching, isUserFetching, history ])
}


export default useVueRoutes
