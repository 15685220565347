import { useMutation } from 'hooks'
import { useEffect, useState } from 'react'


const useAddressByCoords = (coords: [ number, number ], skip?: boolean) => {
  const [ data, setData ] = useState<string>()

  const [ submit, { isSubmitting } ] = useMutation<{ address: string }>({
    url: '/mob_ajax.php?action=geosearch',
  })

  useEffect(() => {
    if (skip || !coords) {
      return
    }

    const body = {
      lat: coords[0],
      lng: coords[1],
    }

    submit(body).then(({ data }) => setData(data.address))
  }, [ coords, skip, submit ])

  return {
    address: data,
    isAddressFetching: isSubmitting,
  }
}


export default useAddressByCoords
