import { useMemo } from 'react'
import { useQuery } from 'hooks'
import dayjs from 'date'


const useSensorsNotifications = ({ skip }) => {
  const querySensorsOptions = useMemo(() => ({
    url: '/smartSensor/rest/monitor/all/events',
    skip,
    modifyResult: (events) => ({
      count: events.length,
      notifications: events
        .map(({ issuedAt, message, monitor }) => ({
          channelId: 'sensors',
          date: dayjs(issuedAt).format('DD MMMM в HH:mm'),
          title: `Счётчик №${monitor.id}`,
          text: message,
        })),
      // notifications: [
      //   {
      //     channelId: 'sensors',
      //     date: dayjs().format('DD MMMM в HH:mm'),
      //     title: `Счётчик №1`,
      //     text: `Сообщение`,
      //   }, {
      //     channelId: 'sensors',
      //     date: dayjs().format('DD MMMM в HH:mm'),
      //     title: `Счётчик №1`,
      //     text: `Сообщение`,
      //   }
      // ],
    }),
  }), [ skip ])

  const {
    data: sensorsData,
    isFetching: isSensorsFetching,
    fetch: fetchSensors,
  } = useQuery(querySensorsOptions)

  return {
    sensorsData,
    isSensorsFetching,
    fetchSensors,
  }
}


export default useSensorsNotifications
