import L, { PathOptions } from 'leaflet'
import { getMapBounds } from 'helpers/getters'
import { Marker, Polygon, Polyline, useMap } from 'react-leaflet'
import React, { useEffect, useMemo, useRef, useState } from 'react'

import { getStorageUrl, IconColor, IconName } from 'components/dataDisplay'

import Popup from './components/Popup/Popup'
import MarkerCluster from './components/MarkerCluster/MarkerCluster'

import marker from './images/marker.svg'

import s from './MapContent.scss'


export const getMarkerIcon = (icon: IconName, color?: IconColor) => {
  if (icon) {
    return (
      L.divIcon({
        html: `
          <div class="${s.placemarkIcon} absolute" style="background-color: white; -webkit-mask: url(${getStorageUrl(icon)}) no-repeat center; mask: url(${getStorageUrl(icon)}) no-repeat center;" ></div>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 36"><path fill="currentColor" fill-rule="evenodd" d="M24 12a12 12 0 10-12 12c1 0 .1 3-.7 6-.9 3.1-1.8 6-.8 6C12.8 36 24 22.8 24 12z" clip-rule="evenodd"/></svg>
        `,
        className: color ? `color-${color}` : 'color-rocky',
        iconSize: [ 24, 37 ], // size of the icon
        iconAnchor: [ 12, 36 ], // point of the icon which will correspond to marker's location
        popupAnchor: [ 122, -31 ],
      })
    )
  } else {
    return (
      L.icon({
        iconUrl: marker,
        iconSize: [ 24, 37 ], // size of the icon
        iconAnchor: [ 12, 36 ], // point of the icon which will correspond to marker's location
        popupAnchor: [ 122, -31 ],
      })
    )
  }
}

type PopupData = {
  subTitle?: string
  title: string
  text: string
  image?: string
  type: string
  iconName?: IconName
  to?: string
}

export type MapContentProps = {
  polylines?: {
    geometry: [ number, number ][]
    popupData?: {
      title: string
    }
    pathOptions?: PathOptions
  }[]
  placemarks?: {
    id: string
    icon?: IconName
    geometry: [ number, number ]
    popupData?: PopupData
    visible?: boolean
  }[]
  multiPolygons?: {
    id: string
    geometries: [ number, number ][]
    color?: string
    popupData: PopupData
  }[]
}

const MapContent: React.FC<MapContentProps> = (props) => {
  const {
    polylines,
    placemarks,
    multiPolygons,
  } = props

  const bounds = getMapBounds({ placemarks, polylines, multiPolygons })
  const map = useMap()

  useEffect(() => {
    if (bounds?.length) {
      map.fitBounds(bounds)
    }
  }, [ bounds, map ])

  const [ isClusterHidden, setClusterHidden ] = useState(false)

  const placemarksRef = useRef(placemarks?.length || 0)

  // TODO temp fix for cluster
  useEffect(() => {
    if (placemarksRef.current && placemarks?.length) {
      setClusterHidden(true)

      const timeout = setTimeout(() => {
        setClusterHidden(false)
      })

      return () => {
        clearTimeout(timeout)
      }
    }

    placemarksRef.current = placemarks?.length || 0
  }, [ placemarks ])

  const clusterNode = useMemo(() => (
    Boolean(placemarks?.length && !isClusterHidden) && (
      <MarkerCluster>
        {
          placemarks.map(({ id, icon, geometry, color, popupData, visible = true, isFavorite, toggleFavorite }, index) => {
            const markerIcon = getMarkerIcon(icon, color)
            return (
              visible && (
                <Marker
                  key={id}
                  position={geometry}
                  icon={markerIcon}
                  bubblingMouseEvents={false}
                  eventHandlers={{
                    // 'click': (event) => console.log(popupData),
                    // 'editable:dragend': (e) => {
                    //   const newGeometry = transformLatLngsToCoords(e.target.getLatLng())
                    //   // handleGeometryUpdate(placemarksField, placemarks, id, newGeometry)
                    // },
                  }}
                >
                  {
                    Boolean(popupData) && (
                      <Popup
                        data={popupData}
                        id={id}
                        isFavorite={isFavorite}
                        toggleFavorite={toggleFavorite}
                      />
                    )
                  }
                </Marker>
              )
            )
          })
        }
      </MarkerCluster>
    )
  ), [ placemarks, isClusterHidden ])

  return (
    <>
      {clusterNode}
      {
        polylines?.map(({ id, geometry, color = '#3d75e4', pathOptions, popupData, isFavorite, toggleFavorite }) => (
          <Polyline
            key={id}
            positions={geometry}
            bubblingMouseEvents={false}
            pathOptions={{
              color: color,
              weight: 2,
              ...pathOptions,
            }}
            // eventHandlers={{
            //   'click': (event) => handleGeometryClick.call(this, event, polylines, id),
            //   'editable:dragend': (e) => {
            //     const newGeometry = transformLatLngsToCoords(e.target.getLatLngs())
            //     // handleGeometryUpdate(polylinesField, polylines, id, newGeometry)
            //   },
            //   'editable:vertex:dragstart': (e) => {
            //     // workaround of this bug https://github.com/Leaflet/Leaflet/issues/4457
            //     map.off('click', handleMapClick)
            //   },
            //   'editable:vertex:dragend': (e) => {
            //     setTimeout(() => map.on('click', handleMapClick), 100) // off bug workaround
            //     const newGeometry = transformLatLngsToCoords(e.target.getLatLngs())
            //     // handleGeometryUpdate(polylinesField, polylines, id, newGeometry)
            //   },
            //   'editable:drawing:commit': (e) => {
            //     const newGeometry = transformLatLngsToCoords(e.target.getLatLngs())
            //     // handleGeometryUpdate(polylinesField, polylines, id, newGeometry)
            //   },
            // }}
          >
            {
              Boolean(popupData) && (
                <Popup
                  data={popupData}
                  id={id}
                  isFavorite={isFavorite}
                  toggleFavorite={toggleFavorite}
                />
              )
            }
          </Polyline>
        ))
      }
      {
        multiPolygons?.map(({ id, geometries, color = '#3d75e4', popupData, isFavorite, toggleFavorite }) => (
          <Polygon
            key={id}
            positions={geometries}
            bubblingMouseEvents={false}
            pathOptions={{
              color: color,
              fillOpacity: 0.16,
              weight: 1,
            }}
            // eventHandlers={{
            //   'click': (event) => handleGeometryClick.call(this, event, multiPolygons, id),
            // }}
          >
            {
              Boolean(popupData) && (
                <Popup
                  data={popupData}
                  id={id}
                  isFavorite={isFavorite}
                  toggleFavorite={toggleFavorite}
                />
              )
            }
          </Polygon>
        ))
      }
    </>
  )
}

export default MapContent