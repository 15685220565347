import cx from 'classnames'

import { useMap } from 'react-leaflet'
import React, { useCallback, useState } from 'react'

import { Knob } from 'components/inputs'

import s from './MapControls.scss'


export type MapControlsProps = {
  withFullScreen?: boolean
  withUserGeolocation?: boolean
}

const MapControls: React.FC<MapControlsProps> = (props) => {
  const { withFullScreen, withUserGeolocation = false } = props

  const map = useMap()

  const [ isFullscreen, setIsFullscreen ] = useState<boolean>(false)

  const toggleFullScreen = () => {
    if (!isFullscreen) {
      map.getContainer().style.position = 'fixed'
      map.getContainer().style.left = '0px'
      map.getContainer().style.top = '0px'
      map.getContainer().style.borderRadius = '0px'
      map.getContainer().style.zIndex = '803' // TODO move z-index to variables
      if (map?.expandButtonRef?.current) {
        map.expandButtonRef.current.style.display = 'none' // hide expand button on <ExpandableMap />
      }
      map.invalidateSize()
      setIsFullscreen(true)
    }
    else {
      map.getContainer().style.position = 'absolute'
      map.getContainer().style.borderRadius = '8px'
      map.getContainer().style.removeProperty('left')
      map.getContainer().style.removeProperty('top')
      map.getContainer().style.removeProperty('z-index')
      if (map?.expandButtonRef?.current) {
        map.expandButtonRef.current.style.display = 'flex' // show expand button on <ExpandableMap />
      }
      map.invalidateSize()
      setIsFullscreen(false)
    }
  }

  const handleUserGeolocationClick = useCallback(() => {
    map.locate({ setView: true, enableHighAccuracy: true })
  }, [ map ])

  return (
    <div className={cx(s.zooms, 'absolute flex flex-col z-800')}>
      <div className={cx(s.controls, 'flex flex-col')}>
        {
          withFullScreen && (
            <Knob
              className={s.shadow}
              icon={isFullscreen ? 'menu/exit-fullscreen_20' : 'menu/fullscreen_20'}
              style="white"
              onClick={(event) => {
                event.stopPropagation()
                toggleFullScreen()
              }}
            />
          )
        }
        <Knob
          className={cx(s.shadow, withFullScreen ? 'mt-16px' : '')}
          icon="main/add_20"
          style="white"
          onClick={(event) => {
            event.stopPropagation()
            map.zoomIn()
          }}
        />
        <Knob
          className={cx(s.shadow, 'mt-4px')}
          icon="main/remove_20"
          style="white"
          onClick={(event) => {
            event.stopPropagation()
            map.zoomOut()
          }}
        />
        {
          withUserGeolocation && (
            <Knob
              className={cx(s.shadow, 'mt-16px')}
              icon="map/location_20"
              style="primary"
              onClick={(event) => {
                event.stopPropagation()
                handleUserGeolocationClick()
              }}
            />
          )
        }
        <div id="add-polygon" className="mt-16px" />
      </div>
    </div>
  )
}


export default MapControls