import cx from 'classnames'
import config from 'config'
import { Field } from 'formular'
import 'leaflet/dist/leaflet.css'
import { useDevice } from 'device'
import React, { useMemo, useState } from 'react'
import L, { LatLngTuple } from 'leaflet'
import { AttributionControl, MapContainer, TileLayer } from 'react-leaflet'

import MapSearch from '../MapSearch/MapSearch'
import Centering from '../Centering/Centering'
import MapControls, { MapControlsProps } from '../MapControls/MapControls'

import './NewMap.scss' // fix leaflet fonts


const mapProviders = [
  {
    name: 'yandex',
    tileUrl: 'https://core-renderer-tiles.maps.yandex.net/tiles?l=map&v=21.07.28-0-b210701140430&x={x}&y={y}&z={z}&scale=2&lang=ru_RU',
  },
  {
    name: 'osm',
    tileUrl: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
  },
]

export type NewMapProps = MapControlsProps & {
  center?: LatLngTuple
  locateUser?: boolean
  searchField?: Field<string>
  addressField?: Field<string>
  withMapControls?: boolean
  withMapSearch?: boolean
  withShortAttribution?: boolean
  onZoomEnd?: ({ zoom, center }) => void
}

const NewMap: React.FC<NewMapProps & Omit<EditableMap.MapProps, 'form'>> = (props) => {
  const {
    children,
    className,
    center = [
      config.cityCenter.lat,
      config.cityCenter.lng,
    ],
    zoom = 13,
    provider = 'yandex',
    whenCreated,
    searchField,
    addressField,
    locateUser,
    withFullScreen,
    withShortAttribution = false,
    withMapControls = true,
    withMapSearch = true,
    withUserGeolocation = false,
    onZoomEnd,
    editable,
    ...rest
  } = props

  const tileUrl = useMemo(() => (
    mapProviders.find((prov) => prov.name === provider).tileUrl
  ), [ provider ])

  const { isDesktop } = useDevice()
  const [ isLoading, setIsLoading ] = useState(true)

  const rootClass = useMemo(() => cx('w-full absolute radius-16 overflow-hidden', {
    [className]: !isLoading,
  }), [ className, isLoading ])

  const attribution = useMemo(() => {
    return provider === 'yandex'
      ? `
        <div style="display: flex; align-items: center;font-size: 12px;">© Яндекс
        ${withShortAttribution ? '' : `<a style="margin-left: 4px; color: black;" href='https://yandex.ru/legal/maps_termsofuse/?lang=ru'>Условия использования</a>`}
        <img style="margin: 0 4px;" src="${config.storage}/common/icons/other/map/yandex-logo.svg"/></div>
      `
      : `© OpenStreetMap`
  }, [ provider, withShortAttribution ])

  const mapNode = (
    <MapContainer
      className={cx(rootClass, 'z-10')}
      style={{ height: isDesktop ? '100%' : 'calc(100% - 56px)' }}
      center={center}
      zoom={zoom}
      scrollWheelZoom={true}
      crs={provider === 'yandex' ? L.CRS.EPSG3395 : L.CRS.EPSG3857}
      editable={editable}
      zoomControl={false}
      attributionControl={false}
      doubleClickZoom={false}
      whenReady={() => setIsLoading(false)}
      whenCreated={(map) => {
        if (locateUser) {
          // TODO set placemark to this point
          map.locate({ setView: true })
        }
        if (typeof whenCreated === 'function') {
          whenCreated(map)
        }
        if (typeof onZoomEnd === 'function') {
          map.on('zoomend', (event) => {
            const { _animateToCenter, _animateToZoom, _lastCenter } = event.target
            const { lat, lng } = _animateToCenter || _lastCenter

            onZoomEnd({
              zoom: _animateToZoom,
              center: [ lat, lng ],
            })
          })
        }
      }}
      {...rest}
    >
      <style>
        {`.leaflet-control-container * {
          z-index: 801;
        }`}
      </style>
      <Centering center={center} zoom={zoom} />
      <TileLayer
        url={tileUrl}
        attribution={attribution}
      />
      {
        withMapSearch && (
          <MapSearch
            addressField={addressField}
            searchField={searchField}
          />
        )
      }
      {
        withMapControls && (
          <MapControls
            withFullScreen={withFullScreen}
            withUserGeolocation={withUserGeolocation}
          />
        )
      }
      {children}
      <AttributionControl position="bottomright" prefix={false} />
    </MapContainer>
  )

  if (isLoading) {
    return (
      <div className={cx(className, 'w-full h-full absolute bone')}>
        <div id="mobileSearchPortal" />
        {mapNode}
      </div>
    )
  }

  return (
    <>
      <div id="mobileSearchPortal" />
      {mapNode}
    </>
  )
}


export default NewMap
