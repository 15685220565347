import cacheStorage from 'cache-storage'

import getModifiedData from './getModifiedData'


const getCache = (cacheKey, modifyResult) => {
  const data = cacheStorage.get(cacheKey)

  if (data) {
    return getModifiedData({ data, url: cacheKey, modifyResult })
  }

  return null
}


export default getCache
