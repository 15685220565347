import React, { useEffect } from 'react'
import { useMap } from 'react-leaflet'
import type { LatLngTuple } from 'leaflet'


type CenteringProps = {
  center: LatLngTuple
  zoom: number
}

const Centering: React.FC<CenteringProps> = ({ center, zoom }) => {
  const map = useMap()

  useEffect(() => {
    map.setView(center, zoom)
  }, [ center, zoom, map ])

  return null
}


export default Centering
