import { useMemo } from 'react'

import useNavigation from './useNavigation'


const useSingleAccessLink = () => {
  const { navigationItems } = useNavigation()

  return useMemo(() => {
    if (navigationItems.length === 1) {
      const subNavItems = navigationItems[0].subNav

      if (subNavItems) {
        if (subNavItems.length === 1) {
          return subNavItems[0].to
        }
      }

      return navigationItems[0].to
    }
  }, [ navigationItems ])
}


export default useSingleAccessLink
