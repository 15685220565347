import { Form, useFormState } from 'formular'
import { getMapBounds } from 'helpers/getters'
import { LatLngExpression, LatLngTuple } from 'leaflet'
import { useGeoCoder } from 'models/mapLayer'
import { useZoom } from 'models/publicTransport'
import { useEffect, useMemo } from 'react'


const useEditableMap = (
  form: Form<EditableMap.MapForm>,
  center: LatLngExpression & LatLngTuple,
  withAddress?: boolean
) => {
  const { values } = useFormState(form)
  const { placemarks, polylines, multiPolygons } = values

  useEffect(() => {
    if (withAddress && !form.fields.address) {
      console.error('EditableMap: withAddress is true but form.fields.address is not provided')
    }
  }, [ withAddress, form ])

  useGeoCoder({
    addressField: form.fields.address,
    placemarksField: form.fields.placemarks,
  }, !withAddress)

  // convert multiPolygons to match MapContentProps['multiPolygons'] type which is used in getMapBounds()
  const multiPolygonsForBounds = useMemo(() => {
    return multiPolygons?.map(({ geometry }) => ({ geometries: geometry.flat(1) }))
  }, [ multiPolygons ])

  const bounds = useMemo(() => getMapBounds({
    placemarks,
    polylines,
    multiPolygons: multiPolygonsForBounds,
  }), [ multiPolygonsForBounds, placemarks, polylines ])

  const isSinglePlacemark = !polylines?.length && !multiPolygons?.length && placemarks?.length === 1
  const initialCenter = useMemo(() => {
    return isSinglePlacemark ? placemarks[0].geometry : center
  }, [ center, isSinglePlacemark, placemarks ])
  const initialZoom = 13
  const { zoom, center: zoomCenter, updateZoom, updateCenter } = useZoom(initialZoom, initialCenter)

  return {
    zoomCenter, bounds, initialZoom, zoom, updateZoom, updateCenter,
  }
}

export default useEditableMap