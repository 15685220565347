import { useMemo } from 'react'
import { useQuery } from 'hooks'


const dashboards = [
  {
    id: 'omsu-all-doc',
    title: 'Все документы',
  },
  {
    id: 'omsu-oncontrol',
    title: 'Документы на контроле',
  },
  {
    id: 'healthomsu',
    title: 'Здравоохранение',
  },
  {
    id: 'monitoring-lesnogo-khozyaistva-lesnichestvo',
    title: 'Мониторинг лесного хозяйства',
  },
  {
    id: 'national-and-federal-projects-omsu',
    title: 'Национальные проекты и госпрограммы',
  },
  {
    id: 'mfc-omsu',
    title: 'МФЦ',
  },
  {
    id: 'obratnaya-svyaz-grazhdan-omsu',
    title: 'Обратная связь граждан',
  },
  {
    id: 'educationomsu',
    title: 'Образование',
  },
  {
    id: 'omsu-gmu',
    title: 'Государственные и муниципальные услуги',
  },
  // {
  //   id: 'taxes-omsu',
  //   title: 'ОМСУ (Налоговая)',
  // },
  {
    id: 'system-112-omsu',
    title: 'Безопасный город',
  },
]

type Input = {
  skip?: boolean
}

// @djdance22: в случае несоответствия новых title городов актуальным, проверьте смену токена
// возьмите новый токен в СЦ (Торопин) и укажите новый токен в конфиге
// Title городов кэшируются на сутки
const useDashboards = ({ skip }: Input) => {
  const queryOptions = useMemo(() => ({
    url: `/ajax.php?action=getOmsuRCCoptions`,
    skip,
    clearOnFetch: false,
  }), [ skip ])

  const { data, isFetching } = useQuery(queryOptions)

  const dashboardsNav = useMemo(() => {
    return data?.dashboards?.map(({ link, title, subtitle }) => ({
      title: subtitle ? `${title} (${subtitle})` : title,
      toTab: link,
    }))
  }, [ data ])

  return {
    data,
    dashboardsNav,
    isFetching,
  }
}


export default useDashboards
