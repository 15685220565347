import * as preact from 'preact'
import AdminLayersNavigation from 'compositions/admin/AdminLayersNavigation/AdminLayersNavigationChunk'
import singleSpaPreact from 'single-spa-preact'
import { setPublicPath } from 'systemjs-webpack-interop'


setPublicPath('@smartsarov/admin-nav')

const preactLifecycles = singleSpaPreact({
  preact,
  rootComponent: AdminLayersNavigation,
})


export const bootstrap = preactLifecycles.bootstrap
export const mount = preactLifecycles.mount
export const unmount = preactLifecycles.unmount
