import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useMutation, useQuery } from 'hooks'

import { useAccesses } from 'models/user/index'

import { useAuth } from 'containers/AuthProvider'


const useNewUser = () => {
  const { token, login, logout, register } = useAuth()
  const tokens = useRef([])

  useEffect(() => {
    if (!tokens.current.includes(token)) {
      tokens.current = tokens.current.concat(token)
    }
  }, [ token ])

  const oldQueryOptions = useMemo(() => ({
    url: '/nginxApi.php?get=user' + `&tokenIndex=${tokens.current.length}`,
    skip: !token,
    // cacheTime: 60 * 1000,
    clearOnFetch: false,
    modifyResult: (data) => {
      return {
        id: data?.userId,
      }
    },
  }), [ token ])

  const queryOptions = useMemo(() => ({
    url: '/auth/rest/user' + `?tokenIndex=${tokens.current.length}`,
    skip: !token,
    // cacheTime: 60 * 1000,
    clearOnFetch: false,
    modifyResult: (data: UserModel.UserApiData) => {
      const { status, user: userData } = data
      const hasAuth = status > 0
      return {
        hasAuth,
        userData: userData || {},
      }
    },
  }), [ token ])

  const {
    data,
    isFetching,
    errors,
  } = useQuery<{ hasAuth: boolean, userData: UserModel.UserApiData['user'] }>(queryOptions)

  useEffect(() => {
    if (!isFetching && !data?.hasAuth) {
      // Remove userId cookie if not authorized
      document.cookie = 'userId=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
    }
  }, [ data, isFetching ])

  const { data: oldData, isFetching: isOldDataFetching } = useQuery<{ id: string }>(oldQueryOptions)

  const [ submit, { isSubmitting } ] = useMutation<UserModel.UserApiData>({
    url: '/auth/rest/',
  })

  const [ remove, { isSubmitting: isRemoveSubmitting } ] = useMutation<UserModel.UserApiData>({
    url: '/auth/rest/user',
  })

  const handleRemove = useCallback(async (userId) => {
    const { data, cacheStorage } = await remove({
      url: `/auth/rest/user/${userId}`,
      method: 'DELETE',
    })

    cacheStorage.reset(/\/auth\/rest\/user\/list/)
    cacheStorage.reset(new RegExp(`/auth/rest/user/${userId}`))
  }, [ remove ])

  // const handleLogin = useCallback(async ({ login, password }: { login: string, password: string }) => {
  //   const { data, cacheStorage } = await submit({
  //     login,
  //     password,
  //   })
  //
  //   window.dispatchEvent(new CustomEvent('login'))
  //
  //   cacheStorage.set('/auth/rest/', {
  //     data,
  //     // expiration: 60 * 1000,
  //   })
  //   cacheStorage.reset('/auth/rest/user')
  //   cacheStorage.reset('/auth/rest/access')
  //   cacheStorage.reset('/auth/rest/accesses')
  //   cacheStorage.reset('/nginxApi.php?get=user')
  // }, [ submit ])

  const { accesses, isAccessesFetching } = useAccesses()

  const adminAccess = useMemo(() => {
    return accesses.some(({ role }) => (
      [ 'ADMIN', 'MODERATOR', 'OPERATOR' ].includes(role)
    ))
  }, [ accesses ])

  const user = useMemo(() => ({
    ...(data?.userData || {}),
    oldId: oldData?.id,
    adminAccess,
    accesses,
  }), [ data, oldData, adminAccess, accesses ])

  useEffect(() => {
    const logUser = (user) => {
      if (!user?.id) {
        window.loggedId = null
      }
      else if (window.loggedId !== user.id) {
        window.loggedId = user?.id
        console.log(`USER ID: ${user.id}`)
        console.log(`USER ID PHP: ${user?.oldId}`)
      }
    }

    window.logUser = window.logUser || logUser
    window.logUser(user)
  }, [ user ])

  return {
    hasAuth: Boolean(token),
    user,
    handleLogin: login,
    handleLogout: logout,
    handleRegister: register,
    handleRemove,
    isUserFetching: isFetching || isAccessesFetching,
    isUserSubmitting: isSubmitting || isRemoveSubmitting,
  }
}


export default useNewUser

