import { useMemo } from 'react'

import { useAccesses, useRoles } from '.'


type ModifiedData = {
  role: UserModel.Role
  roleRu: UserModel.RoleRu
  module: UserModel.AccessApiData['access']['module']
}

type Output = ModifiedData & {
  isModuleAvailable: boolean
  isFetching: boolean
}

const useAccess = (moduleTag: UserModel.ModuleTag): Output => {
  const { roles } = useRoles()
  const { accesses, isAccessesFetching } = useAccesses()

  const access = useMemo<ModifiedData>(() => {
    if (accesses && moduleTag && roles) {
      const access = accesses.find(({ module }) => module.tag === moduleTag)

      if (access) {
        const {
          role,
          ...rest
        } = access

        return {
          role,
          roleRu: roles?.find(({ value }) => value === role)?.title,
          ...rest,
        }
      }
    }

    return null as ModifiedData
  }, [ accesses, roles, moduleTag ])

  return {
    ...access,
    isModuleAvailable: Boolean(access),
    isFetching: isAccessesFetching,
  }
}

export default useAccess