import React, { ReactNode } from 'react'
import links from 'links'

import { Button } from 'components/inputs'
import { IconName, Text } from 'components/dataDisplay'
import { DropdownMenu, DropdownMenuProps, Href } from 'components/navigation'

import OpenButton from './components/OpenButton/OpenButton'
import ChannelButton from './components/ChannelButton/ChannelButton'
import ActionList, { ActionListProps } from './components/ActionList/ActionList'


type DropdownActionProps = {
  id?: string
  className?: string
  icon: IconName
  title: string
  count?: number
  menuItems: DropdownMenuProps['items']
  channelButtonTitle: string
  listItems: ActionListProps['listItems']
  rightNode?: ReactNode
  rightTitleNode?: ReactNode
}

const DropdownAction: React.FC<DropdownActionProps> = (props) => {
  const { id, className, icon, title, count, menuItems, channelButtonTitle, listItems, rightNode, rightTitleNode } = props

  return (
    <DropdownMenu
      className={className}
      itemsNode={(
        <div className="px-16px py-4px" style={{ width: '375px' }}>
          <div className="flex items-center justify-between">
            <Text
              message={title}
              size="h16-20"
              color="titanic"
            />
            {rightTitleNode}
          </div>
          <div className="mt-12px">
            <div className="flex items-center justify-between">
              <DropdownMenu
                items={menuItems}
                style="leftBorder"
                placement="bottomLeft"
              >
                <ChannelButton
                  title={channelButtonTitle}
                />
              </DropdownMenu>
              {rightNode}
            </div>
            {
              count ? (
                <ActionList
                  className="mt-20px"
                  listItems={listItems}
                />
              ) : (
                <Text
                  className="mt-20px opacity-72 text-center"
                  message="Для вас пока нет уведомлений"
                  size="s13-r"
                  color="titanic"
                />
              )
            }
          </div>
          <Href
            to={links.notification.root}
          >
            <Button
              className="mt-4px"
              size={24}
              title="Все уведомления"
              style="secondary"
              fullWidth
            />
          </Href>
        </div>
      )}
      placement="bottomRight"
    >
      <OpenButton
        id={id}
        count={count}
        icon={icon}
      />
    </DropdownMenu>
  )
}


export default React.memo(DropdownAction)
