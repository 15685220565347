import { IconName, TextColor } from 'components/dataDisplay'


type TransportTypeInfo = {
  [ title in PublicTransport.TransportTypeTitle ]: {
    icon: IconName
    color: TextColor
    colorHex: string
    ruTitle: string
    ruTitlePlural: string
  }
}

const titleIconMap: TransportTypeInfo = {
  'BUS': {
    icon: 'transport/bus_16',
    color: 'rocky',
    colorHex: '#3D75E4',
    ruTitle: 'Автобус',
    ruTitlePlural: 'Автобусы',
  },
  'MINIBUS': {
    icon: 'transport/minibus_16',
    color: 'gentlemen',
    colorHex: '#9954F2',
    ruTitle: 'Маршрутка',
    ruTitlePlural: 'Маршрутки',
  },
  'TRAM': {
    icon: 'transport/tram_16',
    color: 'snatch',
    colorHex: '#E9A35D',
    ruTitle: 'Трамвай',
    ruTitlePlural: 'Трамваи',
  },
  'TROLLEY': {
    icon: 'transport/public-transport-trolley_16',
    color: 'matrix',
    colorHex: '#57A003',
    ruTitle: 'Троллейбус',
    ruTitlePlural: 'Троллейбусы',
  },
}

const getTransportTypeInfo = (title: PublicTransport.TransportTypeTitle): TransportTypeInfo[typeof title] => titleIconMap[title]

export default getTransportTypeInfo