import React, { useRef, useState } from 'react'
import { modalVisibility, ModalVisibilityProps, openModal } from 'modal'
import cx from 'classnames'
import { useDevice } from 'device'

import { useUser } from 'models/user'
import { useSystemOptions } from 'models/settings'

import { openConfirmModal } from 'compositions/modals'

import { Modal } from 'components/feedback'
import { ActionButton, Button } from 'components/inputs'
import { Announcement, Text } from 'components/dataDisplay'
import { ScrollableContainer } from 'components/layout'

import Heading from './components/Heading/Heading'
import Settings from './components/Settings/Settings'
import Addresses from './components/Addresses/Addresses'

import { useNotificationSettings } from './util'

import s from './NotificationSettingsModal.scss'


const NotificationSettingsModal: React.FC<ModalVisibilityProps> = (props) => {
  const { closeModal } = props
  const { isMobile } = useDevice()

  const settingsRef = useRef<HTMLDivElement>()
  const addressRef = useRef<HTMLDivElement>()

  const { hasAuth } = useUser()
  const { systemOptions, isSystemOptionsFetching } = useSystemOptions()
  const {
    activeList,
    baseChannels,
    addressesChannels,
    selectedChannels,
    appealsSettings,
    isChanged,
    isFetching,
    isSubmitting,
    selectAll,
    handleUpdate,
    updateChannel,
    setAppealsSettings,
  } = useNotificationSettings()

  const withSms = Boolean(systemOptions?.smsAuth)
  const [ isExpanded, setExpanded ] = useState(false)

  return (
    <Modal
      title="Настройка уведомлений"
      width={618}
      fullWidth
      closeModal={closeModal}
    >
      <div className="w-full">
        {
          !hasAuth &&
            <Announcement
              title="Зарегистрируйтесь, чтобы получать уведомления на email o том, что вам интересно"
            />
        }
        <div style={{ minHeight: 500 }}>
          {
            Boolean(baseChannels.length) && (
              <ScrollableContainer
                ref={settingsRef}
                className="pr-4px"
                withScrollbar
              >
                <Heading
                  className="px-8px"
                  withEmail={hasAuth}
                  activeList={activeList.base}
                  onCheckboxClick={(type) => selectAll({
                    type,
                    channels: baseChannels,
                    isActive: activeList.base[type],
                  })}
                />
                <div className="mt-12px">
                  {
                    baseChannels.map(({ label, value }, index) => {
                      const selectedChannel = selectedChannels[value]
                      const fields = [
                        { id: 'chat', isActive: selectedChannel?.chat },
                        { id: 'email', isActive: selectedChannel?.email },
                      ]

                      const isExpandable = Boolean(label === 'Проблемы' && appealsSettings.length)

                      return (
                        <div
                          key={index}
                          className={cx(s.border, 'px-8px', {
                            'bg-rocky-08': isExpandable && isExpanded,
                            [s.expandable]: isExpandable,
                          })}
                        >
                          <div
                            className={cx({
                              'pointer': isExpandable,
                            })}
                            onClick={() => isExpandable ? setExpanded(!isExpanded) : null}
                          >
                            <Settings
                              fields={fields}
                              label={label}
                              onClick={(field) => {
                                updateChannel({ channelId: value, type: field })

                                if (isExpandable) {
                                  const modifiedData = appealsSettings.map((appealsSettings) => ({
                                    ...appealsSettings,
                                    [field]: !selectedChannel[field],
                                  }))

                                  setAppealsSettings(modifiedData)
                                }
                              }}
                            >
                              {
                                isExpandable && (
                                  <div className={cx('flex ', !isMobile && 'justify-end')}>
                                    <ActionButton
                                      color="rocky"
                                      icon={isExpanded ? 'arrow/arrow-up_16' : 'arrow/arrow-down_16'}
                                    />
                                  </div>
                                )
                              }
                            </Settings>
                          </div>
                          {
                            isExpandable && isExpanded && (
                              <div>
                                {
                                  appealsSettings.map(({ label, value, chat, email }, index) => {
                                    const fields = [
                                      { id: 'chat', isActive: chat, isDisabled: !selectedChannel?.chat },
                                      { id: 'email', isActive: email, isDisabled: !selectedChannel?.email },
                                      // { id: 'chat', isActive: selectedChannel?.chat ? chat : false },
                                      // { id: 'email', isActive: selectedChannel?.email ? email : false },
                                    ]

                                    return (
                                      <Settings
                                        key={index}
                                        className={s.border}
                                        labelClassName="opacity-48"
                                        fields={fields}
                                        label={label}
                                        onClick={(field) => {
                                          const modifiedData = appealsSettings.map((appealSettings) => {
                                            if (appealSettings.value === value) {
                                              const settings = field === 'chat' ? {
                                                email,
                                                chat: !chat,
                                              } : {
                                                chat,
                                                email: !email,
                                              }

                                              return {
                                                ...appealSettings,
                                                ...settings,
                                              }
                                            }

                                            return appealSettings
                                          })

                                          setAppealsSettings(modifiedData)
                                        }}
                                      />
                                    )
                                  })
                                }
                              </div>
                            )
                          }
                        </div>
                      )
                    })
                  }
                </div>
              </ScrollableContainer>
            )
          }
          {
            Boolean(addressesChannels.length) && (
              <>
                <div className="mt-32px">
                  <Text
                    message="Адреса"
                    size="h24"
                    color="titanic"
                  />
                  <Text
                    className="mt-8px opacity-48"
                    message="Когда по выбранным адресам будут проводиться работы или уборка, вам будут отправлены уведомления"
                    size="t16-20"
                    color="titanic"
                  />
                </div>
                <Addresses
                  className="mt-24px"
                />
                <ScrollableContainer
                  ref={addressRef}
                  className="pr-4px"
                  withScrollbar
                >
                  <Heading
                    className="mt-32px px-8px"
                    withSms={withSms && hasAuth}
                    withEmail={hasAuth}
                    activeList={activeList.addresses}
                    onCheckboxClick={(type) => selectAll({
                      type,
                      channels: addressesChannels,
                      isActive: activeList.addresses[type],
                    })}
                  />
                  <div className="mt-12px">
                    {
                      addressesChannels.map(({ label, value }, index) => {
                        const selectedChannel = selectedChannels[value]
                        const commonFields = [
                          { id: 'chat', isActive: selectedChannel?.chat },
                          { id: 'email', isActive: selectedChannel?.email },
                        ]
                        const fields = withSms && hasAuth
                          ? [ ...commonFields, { id: 'sms', isActive: selectedChannel?.sms } ]
                          : commonFields

                        return (
                          <Settings
                            key={index}
                            className={cx(s.border, 'px-8px')}
                            fields={fields}
                            label={label}
                            onClick={(field) => updateChannel({ channelId: value, type: field })}
                          />
                        )
                      })
                    }
                  </div>
                </ScrollableContainer>
              </>
            )
          }
        </div>
        <div style={{ height: '104px' }} />
        <div className="fixed shadow-titanic-1 w-full bottom-0 left-0 z-800 bg-white">
          <div className={ isMobile ? 'pt-32px pb-32px mw-276  margin-auto' : 'pt-32px pb-32px mw-584 margin-auto' }>
            <Button
              title="Сохранить"
              style="primary"
              disabled={!isChanged}
              loading={isSubmitting || isFetching || isSystemOptionsFetching}
              size={40}
              fullWidth
              onClick={() => {
                openConfirmModal({
                  title: 'Сохранить изменения?',
                  text: 'Вы внесли изменения в настройку уведомлений',
                  cancelButtonTitle: 'Не сохранять',
                  confirmButtonTitle: 'Да, сохранить',
                  onConfirm: () => {
                    handleUpdate().then(() => closeModal())
                  },
                })
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}

export const openNotificationSettingsModal = () => openModal('NotificationSettingsModal')


export default modalVisibility('NotificationSettingsModal', NotificationSettingsModal)
