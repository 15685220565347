const getUrl = (url: string) => {
  const isUrlRelative = !/^https?:\/\//.test(url)

  if (__DEV__ && isUrlRelative) {
    return `/proxy${url}`
  }

  return url
}

export default getUrl
