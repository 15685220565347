import config from 'config'
import cacheStorage from 'cache-storage'
import { openNotification } from 'notifications'
import request, { AxiosResponse } from 'axios'
import { useState, useCallback, useRef } from 'react'

import { useAuth } from 'containers/AuthProvider'

import useDeepMemo from '../useDeepMemo'

import { getUrl } from './util'


export type Options = {
  url?: string
  method?: 'POST' | 'DELETE' | 'PUT' | 'GET' | 'PATCH' // GET for multiple query only
  headers?: Record<string, string>
}

type InitialStateType<T> = {
  data: T | null
  isSubmitting: boolean
  errors: object | null
}

type ReturnType<T extends Object> = [
  (body?: any) => Promise<{ data: T, cacheStorage, errors?: any }>,
  InitialStateType<T>
]


const cancelRequest = {}

const useMutation = <T>(options: Options): ReturnType<T> => {
  const { token } = useAuth()

  const tokenRef = useRef(token)
  tokenRef.current = token

  const [ state, setState ] = useState<InitialStateType<T>>({
    data: null,
    errors: null,
    isSubmitting: false,
  })

  cancelRequest[options.url] = request.CancelToken.source()

  const updatedOptions = useDeepMemo(() => ({
    ...options,
    cancelToken: cancelRequest[options.url].token,
  }), [ options ])

  const handleSubmit = useCallback((props = {}) => {
    let url = props?.url || updatedOptions.url
    let body = props?.url ? props.body : props?.body || props
    const method = (props?.url ? props.method || updatedOptions.method : updatedOptions.method) || 'POST'

    const queryUrl = getUrl(url)

    const headers = updatedOptions.headers || {}

    if (!config.isLegacyAuth && tokenRef.current) {
      headers.authorization = `Bearer ${tokenRef.current}`
    }

    const axiosParams = method === 'DELETE'
      ? [ queryUrl, { data: body || {}, headers: headers || {} } ] // workaround of this https://github.com/axios/axios/issues/897
      : [ queryUrl, body, { headers } ]

    setState((prevState) => ({
      ...prevState,
      isSubmitting: true,
    }))

    return request[method.toLowerCase()](...axiosParams)
      .then(
        ({ data, status, statusText }: AxiosResponse) => {
          setState({
            data,
            isSubmitting: false,
            errors: status === 200 ? null : [ { status, statusText } ],
          })

          if (data.error && typeof data.error === 'string') {
            openNotification('plain', {
              title: data.error,
              icon: 'main/warning_16',
              iconColor: 'fargo',
            })
          }

          return { data, cacheStorage }
        },
        (error) => {
          if (error.message) {
            setState({ errors: error.message, isSubmitting: false, data: null }) // FIXME state is not updating on error
            return { errors: error.message }
          }

          return { errors: null }
        }
      )
  }, [ updatedOptions ])

  return [ handleSubmit, state ]
}


export default useMutation
