import React from 'react'
import cx from 'classnames'
import { Field } from 'formular'
import { useDevice } from 'device'
import { createPortal } from 'react-dom'

import { Input } from 'components/inputs'


type MapSearchProps = {
  className?: string
  searchField?: Field<string>
  addressField?: Field<string>
}

const MapSearch: React.FC<MapSearchProps> = (props) => {
  const { className, addressField, searchField } = props

  const { isDesktop, isMobile } = useDevice()

  const searchInputNode = (
    <Input
      className={cx('shadow-medium-titanic radius-8', { 'mb-16px': !isDesktop })}
      field={searchField || addressField}
      label={searchField ? searchField.name || 'Поиск по адресу' : 'Адрес'}
      icon="main/search_16"
      iconPosition="right"
      style={!isDesktop ? 'light' : 'dark'}
    />
  )

  return Boolean(searchField || addressField) && (isDesktop ? (
    <div
      className={cx(className, 'absolute z-1001')}
      style={{ width: !isDesktop ? '100%' : '304px', top: '16px', left: '16px' }}
    >
      {searchInputNode}
    </div>
  ) : (
    createPortal(searchInputNode, document.getElementById('mobileSearchPortal'))
  ))
}

export default MapSearch