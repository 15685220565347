import { useCallback, useMemo } from 'react'
import { useMutation, useQuery } from 'hooks'
import dayjs from 'date'
import links, { getLinkWithParams } from 'links'


const useTKONotifications = ({ skip }) => {
  const queryTKOOptions = useMemo(() => ({
    url: '/trassir/rest/monitoring/common',
    skip,
    modifyResult: (events) => ({
      count: events?.length || 0,
      notifications: (Array.isArray(events) ? events : [])
        .filter((notification) => {
          const allowedTypes = [ 'SMOKE_DETECTED', 'OBJECT_SIZE_ALARM', 'FIRE_DETECTED' ]

          return allowedTypes.includes(notification?.eventType)
        })
        .map((notification) => {
          const {
            eventType, //: "MOTION_START"
            timestamp, //: 1646421797681753
            screenshot: {
              id, //: "comEv-4322-bjNkR512MX1JBQmXxm09"
              saver, //: false
              status, //: "NEW"
              channel: {
                guid, //: "Vq1fSiZ0"
                id: channelId, //: 2
                lat, //: 47.51128
                lng, //: 42.16621
                name, //: "Морская 112"
                saverAvailable, //: false
              },
            },
          } = notification

          const typeName = {
            // MOTION_START: 'Начало движения',
            // MOTION_STOP: 'Конец движения',
            SMOKE_DETECTED: 'Детектирован дым',
            // SMOKE_STOPPED: 'Задымление остановлено',
            OBJECT_SIZE_ALARM: 'Зафиксирован крупногабарит',
            FIRE_DETECTED: 'Зафиксирован огонь',
            // FIRE_STOPPED: 'Возгорание остановлено',
            // FIRE_STOOPED: 'Возгорание остановлено',
          }[eventType]

          const _timestamp = Number(timestamp) * 1000

          return {
            channelId: 'tko',
            date: dayjs(_timestamp).format('DD MMMM в HH:mm'),
            title: typeName ? `${name}. ${typeName}` : name,
            to: getLinkWithParams(links.admin.controlTKO.camera, { itemId: channelId }) + `?archive=${_timestamp}`,
            // text: `Сообщение`,
          }
        }),
    }),
  }), [ skip ])

  const [ submitRead ] = useMutation({
    url: '/trassir/rest/monitoring/common?ack=true',
    method: 'GET',
  })

  const submitReadTKO = useCallback(async (channelId) => {
    if (!channelId || channelId === 'tko') {
      const { data, cacheStorage } = await submitRead()

      cacheStorage.reset('/trassir/rest/monitoring/common')
    }
  }, [ submitRead ])

  const {
    data: tkoData,
    isFetching: isTKOFetching,
    fetch: fetchTKO,
  } = useQuery(queryTKOOptions)

  return {
    tkoData,
    isTKOFetching,
    fetchTKO,
    submitReadTKO,
  }
}


export default useTKONotifications
