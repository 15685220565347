import React from 'react'

import { ActionButton } from 'components/inputs'


type DropdownChannelButtonProps = {
  title: string
  isOpen?: boolean
}

const ChannelButton: React.FC<DropdownChannelButtonProps> = ({ isOpen, title }) => (
  <ActionButton
    className="whitespace-nowrap"
    title={title}
    arrow={isOpen ? 'up' : 'down'}
    color="rocky"
  />
)


export default React.memo(ChannelButton)
