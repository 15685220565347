import React from 'react'
import cx from 'classnames'

import { Href } from 'components/navigation'
import { ActionButton } from 'components/inputs'
import { AspectContainer } from 'components/layout'
import { Image, Text } from 'components/dataDisplay'

import { BigImageData } from '../../Popup'

import s from './BigImageContent.scss'


const BigImageContent: React.FC<Omit<BigImageData, 'type'>> = (props) => {
  const { title, subTitle, text, image, to, withGradient, isCarousel, isFavorite, toggleFavorite, id } = props

  const contentNode = (
    <>
      {
        Boolean(image) && (
          <AspectContainer
            className={withGradient ? 'gradient-titanic-80' : ''}
            aspect={243 / 122}
            fit="cover"
          >
            <Image
              className={cx(s.image, 'w-full')}
              src={image}
              width="243"
              aspect={243 / 122}
            />
          </AspectContainer>
        )
      }
      <div className={`pt-12px pb-16px px-16px ${isCarousel ? 'pb-16px' : 'pb-20px'}`}>
        <Text
          className="opacity-48"
          message={subTitle}
          size="s13-r"
          color="titanic"
        />
        <div className='flex'>
          <Text
            className="mt-4px overflow-ellipsis-3"
            message={title}
            size="h16-20"
            color="titanic"
          />
          {
            isFavorite !== undefined &&
              <ActionButton
                className="ml-6px mt-2px"
                color="rocky"
                icon={isFavorite ? 'action/favorite-add-active_20' : 'action/favorite-add_20'}
                onClick={() => toggleFavorite()}
              />
          }
        </div>
        <Text
          className="mt-4px opacity-72 overflow-ellipsis-3 webkit-box"
          message={text}
          size="s13-r"
          color="titanic"
        />
      </div>
    </>
  )

  return to ? (
    <Href to={to} className="pointer">
      {contentNode}
    </Href>
  ) : (
    contentNode
  )
}

export default BigImageContent