import L from 'leaflet'
import React from 'react'
import cx from 'classnames'
import { useFormState } from 'formular'
import { Marker, Polygon, Polyline } from 'react-leaflet'

import { Knob } from 'components/inputs'
import { Portal } from 'components/layout'
import { Icon, Text } from 'components/dataDisplay'
import Popup from 'compositions/map/MapContent/components/Popup/Popup'
import { getMarkerIcon } from 'compositions/map/MapContent/MapContent'

import s from './EditableMapContent.scss'

import { useEditableMapContent } from './util'


const EditableMapContent: React.FC<EditableMap.MapContentProps> = (props) => {
  const { fixedPlacemarks, form } = props

  const { values } = useFormState(form)
  const { placemarks: placemarksField, polylines: polylinesField, multiPolygons: multiPolygonsField } = form.fields
  const { placemarks, polylines, multiPolygons } = values

  const {
    contextMenuEvent,
    selectedElement,
    markerIcon,
    selectedMarkerIcon,
    setContextMenuEvent,
    handleAddPolygon,
    handleDeletePolygon,
    handleGeometryClick,
    handleGeometryUpdate,
    handleToggleEdit,
  } = useEditableMapContent(form)

  return (
    <>
      {
        (selectedElement instanceof L.Polygon
           || (multiPolygonsField
             && multiPolygons.filter(({ uneditable }) => uneditable !== true).length === 0)) && (
          <Portal id="add-polygon">
            <Knob
              className={cx(s.shadow, 'mt-4px')}
              icon="action/add-directory_20"
              style="white"
              onClick={handleAddPolygon}
            />
          </Portal>
        )
      }
      {
        placemarks?.map(({ id, geometry, title, text, editOn, centerOnUpdate }, index) => (
          <Marker
            key={id}
            position={geometry}
            icon={markerIcon}
            zIndexOffset={fixedPlacemarks?.length ? fixedPlacemarks.length + 1000 : undefined}
            bubblingMouseEvents={false}
            eventHandlers={{
              'click': (event) => handleGeometryClick(event, placemarksField, placemarks, id),
              'editable:enable': (event) => {
                event.target.setIcon(selectedMarkerIcon)
              },
              'editable:disable': (event) => {
                event.target.setIcon(markerIcon)
              },
              'editable:dragend': (e) => {
                handleGeometryUpdate(placemarksField, placemarks, id, e.target.getLatLng())
              },
              'add': (e) => {
                if (editOn) {
                  handleToggleEdit(e)
                }
              },
            }}
          />
        ))
      }
      {
        fixedPlacemarks?.map(({ id, icon, geometry, color, popupData, visible = true }, index) => {
          const markerIcon = getMarkerIcon(icon, color)
          return (
            visible && (
              <Marker
                key={id}
                position={geometry}
                icon={markerIcon}
                bubblingMouseEvents={false}
              >
                {
                  Boolean(popupData) && (
                    <Popup
                      data={popupData}
                    />
                  )
                }
              </Marker>
            )
          )
        })
      }
      {
        polylines?.map(({ id, geometry, color = '#3d75e4', editOn }) => (
          <Polyline
            key={id}
            positions={geometry}
            bubblingMouseEvents={false}
            pathOptions={{
              color: color,
              weight: 1,
            }}
            eventHandlers={{
              'click': (event) => handleGeometryClick.call(this, event, polylinesField, polylines, id),
              'editable:dragend': (e) => {
                handleGeometryUpdate(polylinesField, polylines, id, e.target.getLatLngs())
              },
              'editable:vertex:dragend': (e) => {
                handleGeometryUpdate(polylinesField, polylines, id, e.target.getLatLngs())
              },
              'editable:drawing:commit': (e) => {
                handleGeometryUpdate(polylinesField, polylines, id, e.target.getLatLngs())
              },
              'add': (event) => {
                if (editOn) {
                  handleToggleEdit(event)
                }
              },
            }}
          />
        ))
      }
      {
        multiPolygons?.map(({ id, geometry, color = '#3d75e4', editOn, uneditable, deletable }) => (
          <Polygon
            key={id}
            // @ts-expect-error wrong types on react-leaflet lib
            id={id}
            positions={geometry}
            bubblingMouseEvents={false}
            uneditable={uneditable}
            deletable={deletable}
            pathOptions={{
              color: color,
              fillOpacity: 0.16,
              weight: 1,
            }}
            eventHandlers={{
              'click': handleGeometryClick,
              'editable:dragend': (e) => {
                handleGeometryUpdate(multiPolygonsField, multiPolygons, id, e.target.getLatLngs())
              },
              'editable:vertex:dragend': (e) => {
                handleGeometryUpdate(multiPolygonsField, multiPolygons, id, e.target.getLatLngs())
              },
              'editable:drawing:commit': (e) => {
                handleGeometryUpdate(multiPolygonsField, multiPolygons, id, e.target.getLatLngs())
              },
              'add': (event) => {
                if (editOn) {
                  handleToggleEdit(event)
                }
              },
              'contextmenu': (event) => {
                if (deletable !== false && uneditable !== true) {
                  setContextMenuEvent(event)
                }
              },
            }}
          />
        ))
      }
      {
        contextMenuEvent && (
          <div
            className="absolute z-1001 py-12px px-20px whitespace-nowrap text-left flex items-center
             pointer bg-amelie radius-8"
            style={{
              left: contextMenuEvent.containerPoint.x,
              top: contextMenuEvent.containerPoint.y,
            }}
            onClick={() => {
              handleDeletePolygon(contextMenuEvent)
              setContextMenuEvent(null)
            }}
          >
            <Icon className="mr-12px" name="main/delete_16" color="fargo" />
            <Text
              className="opacity-72 overflow-ellipsis"
              size="c16"
              color="titanic"
              message="Удалить полигон"
            />
          </div>
        )
      }
    </>
  )
}

export default EditableMapContent