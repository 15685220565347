import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useMutation, useQuery } from 'hooks'

import { useAccesses } from 'models/user'


const useAppealsSettings = () => {
  const { accesses } = useAccesses()

  const isUser = !accesses.length

  const queryOptions = useMemo(() => ({
    url: '/ajax.php?action=loadChatChannelsConfig',
    modifyResult: (data) => {
      return {
        creationNotify: {
          chat: Boolean(data?.me?.creationNotifyChat),
          email: Boolean(data?.me?.creationNotifyEmail),
        },
        completionNotify: {
          chat: Boolean(data?.me?.completionNotifyChat),
          email: Boolean(data?.me?.completionNotifyEmail),
        },
        commentNotify: {
          chat: Boolean(data?.me?.commentNotifyChat),
          email: Boolean(data?.me?.commentNotifyEmail),
        },
        publicControlNotify: {
          chat: Boolean(data?.me?.publicControlNotifyChat),
          email: Boolean(data?.me?.publicControlNotifyEmail),
        },
        reportNotify: {
          chat: Boolean(data?.me?.reportNotifyChat),
          email: Boolean(data?.me?.reportNotifyEmail),
        },
        remindNotify: {
          chat: Boolean(data?.me?.remindNotifyChat),
          email: Boolean(data?.me?.remindNotifyEmail),
        },
      }
    },
  }), [])

  const { data: config, isFetching } = useQuery(queryOptions)
  const [ appealsSettings, setAppealsSettings ] = useState([])

  const initialDataRef = useRef([])

  useEffect(() => {
    if (config) {
      const userConfig = [
        {
          value: 'completionNotify',
          deleted: 0,
          emailSubscribed: 0,
          label: 'Смена статуса',
          sms: 0,
          chat: config.completionNotify.chat,
          email: config.completionNotify.email,
        },
        {
          value: 'commentNotify',
          deleted: 0,
          emailSubscribed: 0,
          label: 'Комментарии жителей, исполнителей',
          sms: 0,
          chat: config.commentNotify.chat,
          email: config.commentNotify.email,
        },
      ]

      const adminConfig = [
        {
          value: 'creationNotify',
          deleted: 0,
          emailSubscribed: 0,
          label: 'Поступление нового обращения',
          sms: 0,
          chat: config.creationNotify.chat,
          email: config.creationNotify.email,
        },
        ...userConfig,
        // {
        //   value: 's4',
        //   deleted: 0,
        //   emailSubscribed: 0,
        //   label: 'Все решения проблем (для СМИ)',
        //   sms: 0,
        //   chat: 0,
        //   email: 1,
        // },
        {
          value: 'publicControlNotify',
          deleted: 0,
          emailSubscribed: 0,
          label: 'Народный контроль (оценка горожан по всем проблемам)',
          sms: 0,
          chat: config.publicControlNotify.chat,
          email: config.publicControlNotify.email,
        },
        {
          value: 'reportNotify',
          deleted: 0,
          emailSubscribed: 0,
          label: 'Рапорты исполнителей',
          sms: 0,
          chat: config.reportNotify.chat,
          email: config.reportNotify.email,
        },
        {
          value: 'remindNotify',
          deleted: 0,
          emailSubscribed: 0,
          label: 'Подходит срок исполнения (1 сообщение в день, за 3 дня до наступления срока)',
          sms: 0,
          chat: config.remindNotify.chat,
          email: config.remindNotify.email,
        },
      ]

      initialDataRef.current = isUser ? userConfig : adminConfig

      setAppealsSettings(initialDataRef.current)
    }
  }, [ config, isUser ])

  const appealsChanges = useMemo(() => {
    const emailChanges = appealsSettings.filter(({ value, email }) => {
      const initialSetting = initialDataRef.current.find((initialData) => initialData.value === value)

      return initialSetting.email !== email
    })

    const chatChanges = appealsSettings.filter(({ value, chat }) => {
      const initialSetting = initialDataRef.current.find((initialData) => initialData.value === value)

      return initialSetting.chat !== chat
    })

    return {
      email: emailChanges,
      chat: chatChanges,
    }
  }, [ appealsSettings ])

  const appealsChange = useMemo(() => ({
    email: Boolean(appealsChanges.email.length),
    chat: Boolean(appealsChanges.chat.length),
  }), [ appealsChanges ])

  const [ submit, { isSubmitting } ] = useMutation({
    url: '/ajax.php?action=saveEventsActiveOptions&setForKey=String&on=1|0',
  })

  const updateAppealsSettings = useCallback(async () => {
    const promises = []

    appealsChanges.chat.forEach((setting) => {
      const key = `${setting.value}Chat`

      promises.push(
        submit({
          url: `/ajax.php?action=saveEventsActiveOptions&setForKey=${key}&on=${setting.chat ? 1 : 0}`,
          method: 'POST',
        })
      )
    })
    appealsChanges.email.forEach((setting) => {
      const key = `${setting.value}Email`

      promises.push(
        submit({
          url: `/ajax.php?action=saveEventsActiveOptions&setForKey=${key}&on=${setting.email ? 1 : 0}`,
          method: 'POST',
        })
      )
    })

    const [ { cacheStorage } ] = await Promise.all(promises)

    cacheStorage.reset('/ajax.php?action=loadChatChannelsConfig')
  }, [ appealsChanges, submit ])

  return {
    appealsChange,
    appealsSettings,
    isAppealsSettingsFetching: isFetching,
    setAppealsSettings,
    updateAppealsSettings,
  }
}


export default useAppealsSettings
