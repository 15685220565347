import React, { useContext, useEffect } from 'react'
import cacheStorage from 'cache-storage'


type QueryContext = Record<string, any>

// declare global {
//   interface Window {
//     __QUERY_STATE__: QueryContext
//   }
//
// }

const Context = React.createContext<QueryContext>(null)

const useQueryData = () => useContext<QueryContext>(Context)

type ProviderProps = {
  value: QueryContext
}

const QueryProvider: React.FC<ProviderProps> = ({ children, value }) => {
  const { host, ...rest } = value

  Object.keys(rest).forEach((url) => {
    cacheStorage.set(url, { data: rest[url], expiration: Infinity })
  })

  return (
    <Context.Provider value={{ host }}>
      {children}
    </Context.Provider>
  )
}


export {
  QueryProvider,
  useQueryData,
}
