import { useMemo } from 'react'
import config from 'config'
import { useAccess, useAccesses, useUser } from 'models/user'


const useRole = () => {
  const { user } = useUser()
  const { role } = useAccess('USERS')
  const { accesses } = useAccesses()

  const isLegacyAdminInAnyModule = useMemo(() => (
    config.isLegacyAuth && accesses?.some(({ admin }) => admin)
  ), [ accesses ])

  const isOperator = useMemo(() => (
    config.isLegacyAuth
      ? user?.adminEditAccess || isLegacyAdminInAnyModule
      : role === 'OPERATOR'
  ), [ role, user, isLegacyAdminInAnyModule ])

  const isModerator = useMemo(() => (
    config.isLegacyAuth ? user?.adminEditAccess : role === 'MODERATOR'
  ), [ role, user ])

  const isAdmin = useMemo(() => (
    config.isLegacyAuth ? user?.root : role === 'ADMIN'
  ), [ role, user ])

  const isAdminOrOperator = isAdmin || isOperator

  return {
    isAdmin,
    isOperator,
    isModerator,
    isAdminOrOperator,
  }
}


export default useRole
