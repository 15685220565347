import React from 'react'

import { useNavigation } from 'models/admin'

import { ToggleMenuLink, MenuLink } from 'components/admin'


export type MenuProps = {
  className?: string
  textClassName?: string
  isClosed?: boolean
  onMenuToggle?: (isMenuOpen: boolean) => void
}

const Menu: React.FC<MenuProps> = (props) => {
  const { className, textClassName, isClosed, onMenuToggle } = props

  const { navigationItems } = useNavigation()

  return (
    <div className={className}>
      {
        navigationItems.length > 1 &&
          navigationItems.map((navItem, index) => {
            const { title, icon, to, toTab, href, subNav, count } = navItem

            return (to || toTab || href) ? (
              <MenuLink
                key={index}
                textClassName={textClassName}
                title={isClosed ? '' : title}
                icon={icon}
                to={to}
                toTab={toTab}
                href={href}
                count={count}
              />
            ) : (
              <ToggleMenuLink
                key={index}
                textClassName={textClassName}
                title={isClosed ? '' : title}
                icon={icon}
                subNav={subNav}
                count={count}
                isClosed={isClosed}
                onMenuToggle={onMenuToggle}
              />
            )
          })
      }
    </div>
  )
}


export default Menu
