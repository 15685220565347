import { useEffect, useState, useCallback, useRef } from 'react'
import config from 'config'
import { useUniqueId } from 'hooks'
import cacheStorage from 'cache-storage'
import { openNotification } from 'notifications'
import request, { AxiosResponse } from 'axios'

import { useAuth } from 'containers/AuthProvider'
// import { useQueryData } from 'hooks/query/context'

import useDeepMemo from '../useDeepMemo'

// import { useQueryData } from './context'
import { getModifiedData, getCache, getUrl } from './util'


export type Options = {
  url: string
  onResetCache?: () => any
  modifyResult?: (result: any, url?: string) => any
  skip?: boolean
  json?: boolean
  cacheTime?: number
  clearOnFetch?: boolean
}

type InitialStateType<T> = {
  data: T | null
  isFetching: boolean
  errors: object | null
}

type ReturnType<T extends Object> = InitialStateType<T> & {
  fetch(force?: boolean): Promise<{ data: T } | { errors: any }>
}


const cancelRequest = {}

// 1. If we request the same url from multiple places, we make a request only for the first time,
//    other requests (listeners) will be resolved with the first one
// 2. When we unmount component that is fetching something, we'll cancel the request, but we'll
//    keep fetching if there is at least one listener
const useQuery = <T>(options: Options): ReturnType<T> => {
  const cachedData = useRef(options.skip ? null : getCache(options.url, options.modifyResult))
  const isFetched = useRef(Boolean(cachedData.current || options.skip))
  const queryId = useUniqueId()
  // const { host } = useQueryData()
  const { token } = useAuth()

  const queryIdRef = useRef(queryId)
  const previousUrlRef = useRef(options.url)

  const [ state, setState ] = useState<InitialStateType<T>>({
    data: cachedData.current,
    errors: null,
    isFetching: !options.skip && !cachedData.current,
  })

  const updatedOptions = useDeepMemo(() => ({
    ...options,
  }), [ options ])

  const tokenRef = useRef(token)
  tokenRef.current = token

  const handleFetch = useCallback((force?: false) => {
    const { url, cacheTime, json, clearOnFetch = true, modifyResult } = updatedOptions

    const onChange = (data) => {
      const modifiedData = getModifiedData({ data, url, modifyResult })

      setState({
        data: modifiedData,
        isFetching: false,
        errors: null,
      })

      return modifiedData
    }

    cacheStorage.addListener({
      id: queryIdRef.current,
      key: url,
      event: 'set',
      handler: onChange,
    })

    // TODO make cancel request if called not from useEffect,
    // add to use effect cancel request in return

    // TODO check do we need this?
    const isCacheExist = Boolean(cacheStorage.get(url) && !force)

    if (isCacheExist) {
      // console.info('Fetch has been called with existed cache')

      const cachedData = getCache(url, modifyResult)
      // if (isFetched.current) {
      setState({ data: cachedData, errors: null, isFetching: false })
      // }
      return Promise.resolve(cachedData)
    }

    // Skipped first setState to avoid extra render
    if (isFetched.current && !force && clearOnFetch) {
      // console.error('Clear fetch data')
      setState({ data: null, errors: null, isFetching: true })
    }
    else {
      setState((state) => ({
        ...state,
        isFetching: isFetched.current ? clearOnFetch : true,
      }))
    }

    // listeners[url] = listeners[url] ? listeners[url] + 1 : 1
    const queryUrl = getUrl(url)


    if (!window.activeRequests) {
      window.activeRequests = {}
      window.activeRequestsListeners = {}
    }
    // const activeRequestsListeners: Record<string, number> = {}

    cancelRequest[url] = request.CancelToken.source()
    window.activeRequestsListeners[url] = (window.activeRequestsListeners[url] || 0) + 1

    window.activeRequests[url] = window.activeRequests[url] || request.get(queryUrl, {
      method: 'GET',
      cancelToken: cancelRequest[url].token,
      headers: config.isLegacyAuth
        ? undefined
        : {
          authorization: tokenRef.current ? `Bearer ${tokenRef.current.replace(/\?.+/, '')}` : undefined,
        },
    })

    return window.activeRequests[url]
      .then(
        ({ data }: AxiosResponse) => {
          window.activeRequests[url] = null
          cancelRequest[url] = null
          window.activeRequestsListeners[url] = 0 // activeRequestsListeners[url] - 1

          isFetched.current = true

          // Error if there is no backend, or it returns a string instead of JSON
          if (typeof data === 'string') {
            if (json === false) {
              cacheStorage.set(url, {
                data,
                expiration: typeof cacheTime === 'number' ? Date.now() + cacheTime : Infinity,
              })

              return { data }
            }

            console.error(`Query on ${url} error`, { data })
            setState({ errors: [ { message: `Query on ${url} error, data is not JSON: ${data}` } ], isFetching: false, data: null })

            return { data: null }
          }
          else {
            const arrayField = 'content'
            const withPagination = /(\?|&)limit/.test(url) && /(\?|&)skip/.test(url)

            if (withPagination) {
              const skipValue = url
                .replace(/.*skip=/, '')
                .replace(/&.*/, '')

              const limitValue = url
                .replace(/.*limit=/, '')
                .replace(/&.*/, '')

              const pagesBefore = Number(skipValue) / Number(limitValue)
              const prevList = new Array(pagesBefore).fill(0).map((_, index) => index + 1).reverse()
              const prevRequestsData = {
                ...data,
                payload: {
                  ...data.payload,
                  [arrayField]: [],
                },
              }

              prevList.forEach((count, index) => {
                const prevSkip = Number(skipValue) - Number(limitValue) * count

                let prevData

                if (prevSkip) {
                  const prevUrl = url.replace(/(&|\?)skip=\d*/, `skip=${prevSkip}`)
                  prevData = cacheStorage.get(prevUrl)
                }
                else {
                  const prevUrl = url.replace(/(&|\?)skip=\d*/, '')

                  prevData = cacheStorage.get(prevUrl)
                }

                if (prevData?.payload?.[arrayField]) {
                  prevRequestsData.payload[arrayField] = prevRequestsData.payload[arrayField].concat(prevData.payload[arrayField])
                }
              })

              const mergedData = prevRequestsData.payload[arrayField].concat(data.payload[arrayField])
              const result = {
                ...data,
                payload: {
                  ...data.payload,
                  [arrayField]: mergedData,
                },
              }

              cacheStorage.set(url, {
                data: result,
                expiration: typeof cacheTime === 'number' ? Date.now() + cacheTime : Infinity,
              })

              return { data: onChange(result) }
            }

            if (data.error && typeof data.error === 'string') {
              openNotification('plain', {
                title: data.error,
                icon: 'main/warning_16',
                iconColor: 'fargo',
              })
            }

            cacheStorage.set(url, {
              data,
              expiration: typeof cacheTime === 'number' ? Date.now() + cacheTime : Infinity,
            })

            return { data: onChange(data) }
          }
        },
        (error) => {
          window.activeRequests[url] = null
          cancelRequest[url] = null
          window.activeRequestsListeners[url] = 0 // activeRequestsListeners[url] - 1

          if (error.errors) {
            setState({ errors: error.errors, isFetching: false, data: null })
            return { errors: error.errors }
          }

          return { errors: null }
        }
      )
  }, [ updatedOptions ])

  const handleCancel = useCallback(({ url, queryId }) => {
    const previousUrl = previousUrlRef.current

    if (previousUrl !== url) {
      const isCancelAllowed = !/&page=\d+&pageSize=\d+/.test(previousUrl)

      if (window.activeRequestsListeners[previousUrlRef.current] === 1) {
        isCancelAllowed && cancelRequest[previousUrl].cancel()

        console.log({ previousUrl, url })
      }
      else if (window.activeRequestsListeners[previousUrl]) {
        window.activeRequestsListeners[previousUrl] = window.activeRequestsListeners[previousUrl] - 1
      }
      else if (cancelRequest[previousUrl]) {
        isCancelAllowed && cancelRequest[previousUrl].cancel()
      }

      cacheStorage.removeAllListeners({ key: previousUrl, id: queryId })

      previousUrlRef.current = url
    }
  }, [])

  useEffect(() => {
    const queryId = queryIdRef.current

    // TODO check dev case on marketplace (sport)
    if (typeof handleCancel === 'function') {
      handleCancel({ url: updatedOptions.url, queryId })
    }
    else {
      console.error('handleCancel is not a function:', { queryId })
    }

    if (!updatedOptions.skip) {
      cacheStorage.addListener({
        id: queryId,
        key: updatedOptions.url,
        event: 'reset',
        handler: () => {
          if (updatedOptions.onResetCache) {
            console.log('onResetCache')
            updatedOptions.onResetCache()
          }

          console.log('Reset', updatedOptions.url)
          return handleFetch()
        },
      })

      handleFetch()
    }

    return () => {
      if (window.activeRequestsListeners[updatedOptions.url] === 1) {
        const isCancelAllowed = !/&page=\d+&pageSize=\d+/.test(updatedOptions.url)

        isCancelAllowed && cancelRequest[updatedOptions.url].cancel()
      }

      cacheStorage.removeAllListeners({ key: updatedOptions.url, id: queryId })
    }
  }, [ handleFetch, handleCancel, updatedOptions.skip, updatedOptions.url ])

  return {
    ...state,
    fetch: handleFetch,
  }
}


export default useQuery
