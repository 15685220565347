import React, { useCallback, useEffect, useState } from 'react'
import links from 'links'
import { usePathname } from 'router'
import { Menu } from '@headlessui/react'

import { Text } from 'components/dataDisplay'
import { DropdownMenu, Href } from 'components/navigation'

import OpenButton from 'compositions/admin/mapLayers/AdminHeader/DropdownAction/components/OpenButton/OpenButton'
import ChatButton from 'compositions/admin/mapLayers/AdminHeader/Chat/components/ChatButton'


type ChatProps = {
  className?: string
}

const Chat: React.FC<ChatProps> = (props) => {
  const { className } = props

  const [ count, setCount ] = useState(0)
  const pathname = usePathname()

  const isChatPage = pathname === links.admin.chat

  useEffect(() => {
    window.setChatCount = setCount
  }, [])

  const setAllRead = useCallback(async () => {
    if (typeof window.setChatRead === 'function') {
      window.setChatRead()
    }
  }, [])

  if (!isChatPage) {
    return (
      <Href
        id="chat-button"
        to={`${links.admin.chat}?from=${pathname}`}
      >
        <ChatButton
          className={className}
          initialCount={count}
          setInitialCount={setCount}
        />
      </Href>
    )
  }

  return (
    <DropdownMenu
      className={className}
      itemsNode={(
        <div className="px-16px py-4px" style={{ width: '375px' }}>
          <div className="flex items-center justify-between">
            <Text
              message="Чат"
              size="h16-20"
              color="titanic"
            />
            <Menu.Item
              className="pointer"
              as={Href}
              onClick={setAllRead}
            >
              <Text
                className="opacity-72 overflow-ellipsis webkit-box"
                message="Пометить всё, как прочитанное"
                size="s13-r"
                color="titanic"
              />
            </Menu.Item>
          </div>
        </div>
      )}
      placement="bottomRight"
    >
      <OpenButton
        id="chat-button"
        count={count}
        icon="communication/message_20"
      />
    </DropdownMenu>
  )
}


export default React.memo(Chat)
