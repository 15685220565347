import { useMemo } from 'react'
import dayjs from 'date'
import { useQuery } from 'hooks'
import links, { getLinkWithParams, useModalParams } from 'links'
import { usePathname } from 'router'
import config from 'config'


type UseImmortalsProps = {
  isAdmin?: boolean
  isMy?: boolean
  itemId?: string
  skip?: boolean
}

const useImmortals = ({ isAdmin, isMy, skip }: UseImmortalsProps = {}) => {
  const pathname = usePathname()
  const itemLink = /^\/admin/.test(pathname) ? links.admin.immortals.item : links.immortals.item
  const { itemId } = useModalParams(itemLink)

  const queryOptions = useMemo(() => ({
    url: isMy ? '/immortals/rest/own' : isAdmin ? '/immortals/rest/moderate' : '/immortals/rest/',
    skip,
  }), [ isAdmin, isMy, skip ])

  const { data, isFetching, fetch } = useQuery<Immortals.RestDataItem[]>(queryOptions)

  const heroes = useMemo<Immortals.Hero[]>(() => {
    if (data?.length) {
      return data.map((item) => {
        const {
          id, firstName, lastName, middleName, birth, death,
          sign, specialty, description, created, deleted, imageExist, uuid,
        } = item

        const birthYear = birth ? dayjs(birth).format('YYYY') : ''
        const deathYear = death ? dayjs(death).format('YYYY') : ''

        const fio = [ lastName, firstName, middleName ].filter(Boolean).join(' ')
        const years = (birthYear || deathYear) ? `${birthYear || '...'}–${deathYear || '...'}` : ''
        const title = [ sign, specialty ].filter(Boolean).join(', ')

        const imagePrefix = __DEV__ ? `https://${config.appDomain || 'test.rosatom.city'}/immortals/rest/img/` : '/immortals/rest/img/'

        return {
          id,

          fio,
          firstName,
          lastName,
          middleName,

          sign,
          specialty,
          title,

          birthYear,
          deathYear,
          years,

          description,
          descriptionHtml: description?.replace(/\n/g, '<br />') || '',
          image: imageExist ? `${imagePrefix}${id}` : '', //?id=485&w=350
          to: getLinkWithParams(itemLink, { itemId: id }),

          created,
          deleted,

          published: !isAdmin,

          isMy,
          uuid,
        }
      })
        .filter(({ deleted }) => !deleted)
    }

    return []
  }, [ data, isAdmin, isMy, itemLink ])

  const hero = useMemo(() => {
    if (itemId) {
      return heroes.find(({ id }) => String(id) === itemId)
    }

    return null
  }, [ heroes, itemId ])

  return {
    hero,
    heroes,
    isFetching,
    fetch,
  }
}


export default useImmortals
