import React from 'react'
import { DeviceProvider } from 'device'
import { Router } from 'router'
import { QueryProvider } from 'hooks/query/context'

import { AuthProvider } from 'containers/AuthProvider'

import AdminLayersNavigation from 'compositions/admin/AdminLayersNavigation/AdminLayersNavigation'


const AdminLayersNavigationChunk: React.FC = () => (
  <DeviceProvider value={window.__DEVICE__}>
    <QueryProvider value={window.__QUERY_STATE__}>
      <AuthProvider>
        <Router>
          <AdminLayersNavigation />
        </Router>
      </AuthProvider>
    </QueryProvider>
  </DeviceProvider>
)


export default AdminLayersNavigationChunk
