import { useHistory } from 'router'
import React, { useCallback } from 'react'
import { modalVisibility, ModalVisibilityProps, openModal } from 'modal'

import { Modal } from 'components/feedback'
import { Icon, Text } from 'components/dataDisplay'
import { Button } from 'components/inputs'


type ConfirmModalProps = {
  text?: string
  title?: string
  redirectLink?: string
  confirmButtonTitle?: string
  cancelButtonTitle?: string
  exitButtonTitle?: string
  onConfirm: () => void
  onCancel?: () => void
}

const ConfirmModal: React.FC<ConfirmModalProps & ModalVisibilityProps> = (props) => {
  const { closeModal,
    text, title, redirectLink, confirmButtonTitle, cancelButtonTitle, exitButtonTitle,
    onConfirm, onCancel } = props
  const history = useHistory()

  const handleConfirm = useCallback(async () => {
    await onConfirm()
    closeModal()

    if (redirectLink) {
      history.push(redirectLink)
    }
  }, [ closeModal, history, onConfirm, redirectLink ])

  const handleCancel = useCallback(() => {
    if (typeof onCancel === 'function') {
      onCancel()
    }
    closeModal()
  }, [ closeModal, onCancel ])

  return (
    <Modal
      title=""
      closeModal={closeModal}
      width={618}
    >
      <div className="flex flex-col justify-center">
        <Icon
          name="main/warning_56"
          color="fargo"
        />
        <Text
          className="mt-32px"
          message={title || 'Вы уверены, что хотите внести изменения?'}
          size="h32"
        />
        {
          text && (
            <Text
              className="mt-16px opacity-48"
              message={text}
              size="t16-24"
            />
          )
        }
        <div className="flex mt-32px">
          {
            exitButtonTitle && (
              <Button
                className="flex-1"
                title={exitButtonTitle || 'Выйти'}
                style="accent"
                onClick={() => closeModal()}
                fullWidth
                size={40}
              />
            )
          }
          <Button
            className="flex-1 ml-24px"
            title={cancelButtonTitle || 'Нет, отменить'}
            style="secondary"
            onClick={handleCancel}
            fullWidth
            size={40}
          />
          <Button
            className="ml-24px flex-1"
            title={confirmButtonTitle || 'Да, внести'}
            style="primary"
            onClick={handleConfirm}
            fullWidth
            size={40}
          />
        </div>
      </div>
    </Modal>
  )
}

export const openConfirmModal = (props: ConfirmModalProps) => openModal('confirmDeleteModal', props)


export default modalVisibility('confirmDeleteModal', ConfirmModal)