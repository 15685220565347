import React from 'react'

import { Href } from 'components/navigation'
import { Carousel, AvatarHorizontallyBlock, RouteIcon, Text, Icon } from 'components/dataDisplay'

import loaderImage from 'components/dataDisplay/Image/images/spinner.svg'
import noPhotoAvatar from 'components/dataDisplay/Image/images/noPhotoAvatar.png'

import BigImageContent from '../BigImageContent/BigImageContent'
import { PopupData } from '../../Popup'


type PopupContentProps = {
  className?: string
  id?: string
  data?: PopupData | PopupData[]
  isFavorite?: boolean
  toggleFavorite?: () => void
}

const PopupContent: React.FC<PopupContentProps> = (props) => {
  const { className, data, isFavorite, id, children, toggleFavorite } = props

  const dataArray = Array.isArray(data) ? data : [ data ]
  const { type } = dataArray[0] || {}
  const isCarousel = dataArray.length > 1

  return (
    <>
      {(type === 'bigImage' ? (
        <Carousel
          withDots={isCarousel}
          withArrows={isCarousel}
          prevIcon={(
            <Icon name="arrow/arrow-left_16" color="rocky" />
          )}
          nextIcon={(
            <Icon name="arrow/arrow-right_16" color="rocky" />
          )}
          slidesToShow={1}
        >
          {
            dataArray.map(({ title, subTitle, text, image, to, withGradient }, index) => (
              <BigImageContent
                key={index}
                title={title}
                subTitle={subTitle}
                text={text}
                image={image}
                to={to}
                withGradient={withGradient}
                isCarousel={isCarousel}
                isFavorite={isFavorite}
                toggleFavorite={toggleFavorite}
                id={id}
              />
            ))
          }
        </Carousel>
      ) : type === 'staffList' ? (
        <div className="p-16px">
          <Href to={dataArray[0].to}>
            <Text
              className="opacity-48"
              message={dataArray[ 0 ].title}
              size="s10"
              color="titanic"
            />
            {
              dataArray[ 0 ]?.staff?.map(({ name, image }, index) => (
                <AvatarHorizontallyBlock
                  className="mt-12px"
                  key={index}
                  userName={name}
                  image={image ? `${image}&w=104&crop=1` : noPhotoAvatar} // TODO replace with Image
                  size={56}
                  margin={16}
                  userNameTextSize="h16-20"
                  positionTextSize="t16-20"
                  withAvatar={true}
                />
              ))
            }
          </Href>
        </div>
      ) : type === 'busCard' ? (
        <>
          {
            dataArray[0].isFetching ? (
              <div
                className="flex flex-col items-center justify-center"
                style={{
                  height: '112px',
                }}
              >
                <div className="flex items-center">
                  <img
                    src={loaderImage}
                    width={16}
                    height={16}
                  ></img>
                </div>
              </div>
            ) : (
              <Href className="p-16px flex" to={dataArray[0].to}>
                <RouteIcon
                  transportType={dataArray[0].transportType}
                  withInvalid={dataArray[0].withInvalid}
                />
                <div className="ml-8px">
                  <Text
                    message={dataArray[ 0 ].title}
                    size="s13-b"
                    color="titanic"
                  />
                  <Text
                    className="mt-4px"
                    message={dataArray[ 0 ].subTitle}
                    size="s10"
                    color="rocky"
                  />
                  <Text
                    className="mt-8px px-4px bg-apocalypse opacity-48"
                    message={dataArray[ 0 ].text}
                    size="s10"
                    color="titanic"
                  />
                </div>
              </Href>
            )
          }
        </>
      ) : type === 'simpleCard' ? (
        <Carousel
          withDots={isCarousel}
          withArrows={isCarousel}
          prevIcon={(
            <Icon name="arrow/arrow-left_16" color="rocky" />
          )}
          nextIcon={(
            <Icon name="arrow/arrow-right_16" color="rocky" />
          )}
          slidesToShow={1}
        >
          {
            dataArray.map(({ title, subTitle, text, to, children }, index) => (
              <Href className="p-16px flex flex-col items-start" key={index} to={to}>
                <Text
                  message={title}
                  size="s13-b"
                  color="titanic"
                />
                <Text
                  className="mt-4px opacity-72"
                  message={subTitle}
                  size="s10"
                  color="titanic"
                />
                {
                  children
                }
              </Href>
            ))
          }
        </Carousel>
      ) : (
        <div className={className}>
          {children}
        </div>
      ))}
    </>
  )
}

export default PopupContent