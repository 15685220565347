import React from 'react'
import cx from 'classnames'

import { useUser } from 'models/user'

import { ButtonBase } from 'components/inputs'
import { CheckboxIcon, Text } from 'components/dataDisplay'

import s from '../../NotificationSettingsModal.scss'


type HeadingProps = {
  children?: React.ReactNode
  className?: string
  labelClassName?: string
  fields: { id: string, isActive: boolean , isDisabled?: boolean }[]
  label: string
  onClick: (id: string) => void
}

const Settings: React.FC<HeadingProps> = (props) => {
  const { children, className, labelClassName, fields, label, onClick } = props
  const { hasAuth } = useUser()

  return (
    <div
      className={cx(className, s.grid, 'grid w-full py-16px')}
    >
      <Text
        className={cx(s.channel,labelClassName, 'overflow-ellipsis')}
        message={label}
        size="t16-20"
        color="titanic"
      />
      {
        fields.map(({ id, isActive, isDisabled }, fieldIndex) => (
          <div
            key={fieldIndex}
            className={cx('flex items-center', {
              'opacity-64': isDisabled,
            })}
          >
            <ButtonBase
              disabled={!hasAuth || isDisabled}
              onClick={() => onClick(id)}
            >
              <CheckboxIcon
                active={isActive}
              />
            </ButtonBase>
          </div>
        ))
      }
      {children}
    </div>
  )
}


export default React.memo(Settings)
