import { useCallback, useMemo } from 'react'
import { useMutation, useQuery } from 'hooks'
import { openNotification } from 'notifications'


const usePersonalOptions = () => {
  const queryOptions = useMemo(() => ({
    url: '/ajax.php?action=getPersonalOptions',
    clearOnFetch: false,
    modifyResult: (data) => {
      return {
        ...data,
        addresses: data?.addresses?.filter((addressItem) => addressItem?.address),
      }
    },
  }), [])

  const { data: personalOptions, isFetching } = useQuery<UserModel.PersonalOptionsApiData>(queryOptions)

  const [ submit, { isSubmitting } ] = useMutation({
    url: '/ajax.php?action=setPersonalOptions',
  })

  const addAddress = useCallback(async (address: UserModel.PersonalOptionsApiData['addresses'][number]) => {
    if (personalOptions) {
      const { data, cacheStorage } = await submit({
        url: '/ajax.php?action=setPersonalOptions',
        method: 'POST',
        body: {
          ...personalOptions,
          addresses: personalOptions.addresses.concat(address),
        },
      })

      if (!data?.error) {
        cacheStorage.reset('/ajax.php?action=getPersonalOptions')
      }
    }
  }, [ personalOptions, submit ])

  const deleteAddress = useCallback(async (index: number) => {
    if (personalOptions) {
      const addresses = personalOptions.addresses?.filter((_, addressIndex) => addressIndex !== index)

      const { data, cacheStorage } = await submit({
        url: '/ajax.php?action=setPersonalOptions',
        method: 'POST',
        body: {
          ...personalOptions,
          address: addresses[0]?.address || '',
          addresses,
        },
      })

      if (!data?.error) {
        cacheStorage.reset('/ajax.php?action=getPersonalOptions')
      }
    }
  }, [ personalOptions, submit ])

  return {
    personalOptions,
    isFetching,
    isSubmitting,
    addAddress,
    deleteAddress,
  }
}


export default usePersonalOptions
