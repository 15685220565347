/*
  Transforms object or array of <LatLngLiteral> objects { lat: number, lng:number } to object or array of
  [number, number] coordinates
  Array can be nested (i.e. polyline, polygon, multiPolygon)

  Examples:

  { lat: 45, lng: 46 }

  to

  [ 45, 46 ]

  ==================

  [
    [
      [
        { lat: 45, lng: 46},
      ]
    ]
  ]

  to

  [
    [
      [
        [ 45, 46 ]
      ]
    ]
  ]
*/

import { LatLngLiteral, LatLngTuple } from 'leaflet'


export type GeometryLatLngExpression = LatLngLiteral | LatLngLiteral[] | LatLngLiteral[][] | LatLngLiteral[][][]
type GeometryLatLngTuple = LatLngTuple | LatLngTuple[] | LatLngTuple[][] | LatLngTuple[][][]

const transformLatLngsToCoords = (entity: GeometryLatLngExpression): GeometryLatLngTuple => {
  if (!Array.isArray(entity)) {
    return [ entity.lat, entity.lng ]
  } else {
    return entity.map((element) => transformLatLngsToCoords(element))
  }
}

export default transformLatLngsToCoords