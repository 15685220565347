import { useMemo } from 'react'
import { useQuery } from 'hooks'


const modifyPhpBoolean = (data: string | boolean): boolean => (
  typeof data === 'string' ? data !== '0' : Boolean(data)
)

const useSystemOptions = (skip?: boolean) => {
  const options = useMemo(() => ({
    url: '/ajax.php?action=loadSystemOptions',
    skip,
    modifyResult: (data: Settings.SystemOptionsApiData) => {
      if (data) {
        const { all, ...rest } = data || {}
        return {
          ...rest,
          ...(all || {}),
          designOptions: {
            ...data.designOptions,
            problemCreateHint: data.designOptions.problemCreateHint || '',
            hideProblemComments: modifyPhpBoolean(data.designOptions.hideProblemComments),
            hideProblemEdit: modifyPhpBoolean(data.designOptions.hideProblemEdit),
            hideProblemRate: modifyPhpBoolean(data.designOptions.hideProblemRate),
            userCount: modifyPhpBoolean(data.designOptions.userCount),
          },
        }
      }

      return {}
    },
  }), [ skip ])

  const { data, isFetching, errors } = useQuery<Settings.SystemOptions>(options)

  return {
    systemOptions: data || {} as Settings.SystemOptions,
    isSystemOptionsFetching: isFetching,
    systemOptionsErrors: errors,
  }
}

export default useSystemOptions