import { useCallback, useEffect, useRef, useState } from 'react'
import { io } from 'socket.io-client'
import { useUser } from 'models/user'
import request from 'axios'
import type { Socket } from 'socket.io-client'
import { openNotification } from 'notifications'


type State = {
  socket: Socket
  isConnected: boolean
}

const handleLog = ({ url, data }) => {
  console.log('[SOCKET]', { url, data })

  if (data?.error && url.includes('action=operateChatChannel')) {
    openNotification('plain', {
      title: data.error,
      icon: 'main/warning_16',
      iconColor: 'fargo',
    })
  }
}

const useSocket = () => {
  const { user, isUserFetching } = useUser()

  const [ state, setState ] = useState<State>({
    socket: null,
    isConnected: false,
  })

  const {
    socket,
    isConnected,
  } = state

  const userIdRef = useRef(user?.id)
  userIdRef.current = user?.id

  const socketRef = useRef(socket)
  socketRef.current = socket

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.socket = socket
    }
  }, [ socket ])

  const authSocket = useCallback((socketId) => {
    const webHookUrl = /localhost/.test(window.location.origin)
      ? 'http://localhost:8001/socket/rest/?socketId=' + socketId + '&userId=' + userIdRef.current
      : window.location.origin + '/socket/rest/?socketId=' + socketId

    return request.get(webHookUrl, {
      headers: {
        authorization: window.token ? `Bearer ${window.token}` : undefined,
      },
    })
      .catch((error) => console.log({ error }, `can't connect ${webHookUrl}`))
  }, [])

  const isClientCreated = useRef(false)
  const userId = user?.id
  const userEmailRef = useRef(user?.email)
  userEmailRef.current = user?.email

  useEffect(() => {
    if (userId && !isClientCreated.current) {
      isClientCreated.current = true

      const extraHeaders = {
        id: userId,
        email: userEmailRef.current,
      }

      const socketUrl = /localhost/.test(window.location.origin)
        ? 'http://localhost:8000/'
        : window.location.origin // + '/socket'

      const client = io(socketUrl, {
        extraHeaders,
        // withCredentials: true,
      })

      client.on('log', handleLog)

      console.log('connecting', socketUrl)
      client.on('connect', () => {

        console.log('authorising', client.id)
        authSocket(client.id)
          .then((data) => console.log(data))

        client.on('clientConnected', () => {
          setState({ socket: client, isConnected: true })

          console.log('set connected state')
          client.off('clientConnected')
        })
      })

      client.on('disconnect', () => {
        console.log('disconnect')
        setState((state) => ({ ...state, isConnected: false }))
      })

      return () => {
        console.log('client off')
        client.off('log')
        client.off('connect')
        client.off('clientConnected')
        client.off('disconnect')
      }
    }
  }, [ userId, authSocket, setState ])

  return {
    socket,
    isConnected,
  }
}


export default useSocket
