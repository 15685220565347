import config from 'config'
import { LatLngTuple } from 'leaflet'
import { useCallback, useState } from 'react'


const useZoom = (initialZoom: number, initialCenter: LatLngTuple = config.cityCenter.latLng) => {
  const [ center, setCenter ] = useState<LatLngTuple>(initialCenter)
  const [ zoom, setZoom ] = useState<number>(initialZoom)

  const updateZoom = useCallback((newZoom) => {
    setZoom(newZoom)
  }, [])

  const updateCenter = useCallback((newCenter) => {
    if (newCenter[0] !== center[0] || newCenter[1] !== center[1]) {
      setCenter(newCenter)
    }
  }, [ center ])

  return {
    zoom,
    center,
    isBigIcons: zoom <= 13,
    updateZoom,
    updateCenter,
  }
}


export default useZoom

