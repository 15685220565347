import { useCallback } from 'react'


const roles: roleData[] = [
  {
    title: 'Житель',
    value: 'ANONYMOUS',
  },
  {
    title: 'Оператор',
    value: 'OPERATOR',
  },
  {
    title: 'Модератор',
    value: 'MODERATOR',
  },
  {
    title: 'Администратор',
    value: 'ADMIN',
  },
]

const rolesList = roles.map((role) => role.value)

type roleData = {
  title: UserModel.RoleRu
  value: UserModel.Role
}

const useRoles = () => {
  const checkIsRoleLess = useCallback((firstRole: UserModel.Role, secondRole: UserModel.Role) => (
    rolesList.indexOf(firstRole) < rolesList.indexOf(secondRole)
  ), [])

  return {
    roles,
    checkIsRoleLess,
  }
}


export default useRoles
