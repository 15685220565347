import { useCallback, useEffect, useMemo, useState } from 'react'
import { useUser } from 'models/user'
import { useChannels, useNotifications } from 'models/notifications'
import localStorage from 'local-storage'


const useVisitedNotifications = () => {
  const { user } = useUser()
  const { channels } = useChannels()
  const { count, notifications, notificationLinks } = useNotifications()
  const localStorageName = user?.id ? `${user.id}_notifications_visited` : 'notifications_visited'

  const [ visitedLinks, setVisitedLinks ] = useState([])

  useEffect(() => {
    setVisitedLinks(localStorage.getItem(localStorageName) || [])
  }, [ localStorageName ])

  useEffect(() => {
    if (notificationLinks) {
      const localStorageLinks = localStorage.getItem(localStorageName) || []
      const filteredLinks = localStorageLinks.filter((link) => notificationLinks.includes(link))

      localStorage.setItem(localStorageName, filteredLinks)
      setVisitedLinks(filteredLinks)
    }
  }, [ notificationLinks, localStorageName ])

  const setVisited = useCallback((link) => {
    const localStorageLinks = localStorage.getItem(localStorageName) || []

    if (!localStorageLinks.includes(link)) {
      const updatedLinks = localStorageLinks.concat(link)

      localStorage.setItem(localStorageName, updatedLinks)
      setVisitedLinks(updatedLinks)
    }
  }, [ localStorageName ])

  const counts = useMemo(() => {
    return notifications.reduce((result, { to, channelId, readed }) => {
      const channelName = channels.find(({ id }) => id === channelId)?.name || channelId

      result[channelName] = result[channelName] || 0

      if (!visitedLinks.includes(to) && !readed) {
        result.all = result.all + 1
        result[channelName] = result[channelName] + 1
      }

      return result
    }, { all: 0 })
  }, [ notifications, visitedLinks, channels ])

  return {
    counts,
    visitedLinks,
    setVisited,
  }
}


export default useVisitedNotifications
