import config from 'config'
import links, { routeData } from 'links'
import { useCallback, useMemo } from 'react'
import { usePathname, useSearchParams } from 'router'
import { getAdminModuleTitle } from 'models/settings'

import { useVueRoutes } from 'models/admin'
import { useAccesses } from 'models/user'
import { useVisitedNotifications } from 'models/notifications'

import { IconName } from 'components/dataDisplay'
import { MenuLinkItem } from 'components/admin'

import adminLinks from './adminLinks'
import useDashboards from './useDashboards'


const getModuleRole = (accesses: UserModel.AccessesApiData['accesses'], module: UserModel.ModuleTag) => {
  return accesses?.find?.((access) => access.module.tag === module)?.role
}

const hasAccess = ({ to, tag, accessRoles, accesses }: {
  to: string,
  tag?: UserModel.ModuleTag,
  accessRoles: string[],
  accesses: UserModel.AccessesApiData[ 'accesses' ],
}) => {
  if (to && typeof to === 'object') {
    console.error('Wrong link provided', to)
  }

  const accessRole = getModuleRole(accesses, tag || routeData[to]?.tag)

  return !accessRoles || accessRoles.includes(accessRole)
}

type AdminLayersNavigationMenuItem = MenuLinkItem<{
  to?: string
  toTab?: string
  href?: string
  subNav?: SubNav[]
}>

type SubNav = MenuLinkItem<{ to?: string, toTab?: string, href?: string, icon: IconName }>

export const useNavigationItems = (props: { counts?: Record<string, number> } = {}) => {
  const { counts = {} } = props
  const { accesses } = useAccesses()
  const { dashboardsNav } = useDashboards({
    skip: !accesses.some(({ module }) => module.tag === 'RCC'),
  })

  const navigationItems = useMemo<AdminLayersNavigationMenuItem[]>(() => {
    const infraCounts = {
      [links.admin.controlTKO.root]: counts.tko || 0,
      [links.admin.cleaning.root]: counts.cleaning || 0,
      [links.admin.excavation.root]: counts.excavation || 0,
    }

    const projectsCounts = {
      [links.admin.immortals.root]: counts.immortals || 0,
      [links.manager.projectGv.root]: counts['cityProjects-gv'] || 0,
      [links.manager.projectIb.root]: counts['cityProjects-ib'] || 0,
      [links.manager.projectSz.root]: counts['cityProjects-sz'] || 0,
    }

    const countsByLink = {
      [links.admin.appeals.root]: counts.appeals,
      [links.manager.news.root]: counts.news,
      [links.admin.culture.root]: counts.culture,
      [links.admin.counters.root]: counts.sensors,
      ...infraCounts,
    }

    const countsByTitle = {
      'Активный житель': counts.appeals,
      'Умные устройства': counts.sensors,
      'Городская инфраструктура': Object.values(infraCounts).reduce((acc, count) => acc + count, 0),
      'Проекты': Object.values(projectsCounts).reduce((acc, count) => acc + count, 0),
    }

    const items =  adminLinks
      .map((adminLink) => {
        if (adminLink.tag === 'RCC') {
          return {
            ...adminLink,
            subNav: dashboardsNav,
          }
        }

        return adminLink
      })
      .map((adminLink) => {
        const { title, to, toTab } = adminLink

        return {
          ...adminLink,
          count: countsByTitle[title] || countsByLink[to || toTab],
          subNav: adminLink.subNav?.map((subNavItem) => ({
            ...subNavItem,
            count: countsByLink[subNavItem.to || subNavItem.toTab],
          })),
        }
      })
      .reduce((result, item) => {
        if (item.subNav) {
          const filteredSubNav = item.subNav
            .filter(({ to, toTab, tag, accessRoles }) => {
              return hasAccess({ to: to || toTab, tag, accessRoles, accesses })
            })
            .map((item) => ({
              ...item,
              title: item.title || routeData[item.to]?.title,
              tag: item.tag || routeData[item.to]?.tag || routeData[item.toTab]?.tag,
            }))

          if (filteredSubNav.length) {
            result.push({
              ...item,
              subNav: filteredSubNav,
            })
          }
        }
        else {
          const isUsersItem = item.to === links.admin.users.root
          const isLegacyAdmin = config.isLegacyAuth && accesses.some(({ admin }) => admin)

          const isItemVisible = hasAccess({
            to: item.to || item.toTab,
            accessRoles: item.accessRoles,
            accesses,
          })

          if (isItemVisible || (isLegacyAdmin && isUsersItem)) {
            result.push({
              ...item,
              tag: routeData[item.to || item.toTab]?.tag,
            })
          }
        }

        return result
      }, [])

    const itemsWithTitles = items.map((item) => {
      const { subNav, ...rest } = item

      if (subNav) {
        return {
          ...rest,
          subNav: subNav.map((subNavItem) => ({
            ...subNavItem,
            title: getAdminModuleTitle(accesses, subNavItem.tag) || subNavItem.title,
          })),
        }
      }
      else {
        return {
          ...rest,
          title: item.title || getAdminModuleTitle(accesses, item.tag),
        }
      }
    })

    return itemsWithTitles
  }, [ accesses, counts, dashboardsNav ])

  const propsByPathname = (propName) => navigationItems.reduce((result, navItem) => {
    if (navItem.to) {
      result[navItem.to] = navItem[propName]
    }
    if (navItem.subNav) {
      navItem.subNav.forEach((item) => {
        const { to } = item
        result[to] = item[propName]
      })
    }

    return result
  }, {})

  const titlesByPathname = propsByPathname('title')
  const tagsByPathname = propsByPathname('tag')

  return { navigationItems, titlesByPathname, tagsByPathname }
}


const useNavigation = () => {
  useVueRoutes()

  const { counts } = useVisitedNotifications()
  const { navigationItems, titlesByPathname, tagsByPathname } = useNavigationItems({ counts })

  const pathname = usePathname()
  const { from } = useSearchParams()

  const tagKey = Object.keys(tagsByPathname).reverse().find((path) => pathname.includes(path))

  const matchLink = useCallback((link) => {
    const linkAdminPath = link.replace(/^\/admin/, '')
    const adminPath = (from || pathname).replace(/^\/admin/, '')

    if (!linkAdminPath) return !adminPath

    return adminPath.startsWith(linkAdminPath)
  }, [ pathname, from ])

  return {
    matchLink,
    navigationItems,
    title: titlesByPathname[pathname],
    tag: tagsByPathname[tagKey],
  }
}


export default useNavigation
