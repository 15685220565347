import { useEffect } from 'react'


const useNotificationsUpdate = ({ fetch }) => {

  useEffect(() => {
    const interval = setInterval(fetch, 30000)

    return () => {
      clearInterval(interval)
    }
  }, [ fetch ])
}


export default useNotificationsUpdate
